.workpath-grid {
  @include transition(opacity 0.5s);

  padding-top: em($global-header-height);
  position: absolute;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
  z-index: 2;

  &--deactivate {
    transition: opacity 0.5s $transition-easing,
      visibility 100ms linear 0.5s;
    
    visibility: hidden;
    opacity: 0;
  }
  
  &__column {
    padding: em($spacing__sm) 0 0;
  }
  
  @include respond-to('between') {
    padding-top: em($global-header-height--tablet);
    display: flex;
    flex-wrap: wrap;
    height: 100vh;

    &__column {
      width: 50%;
      display: inline-flex;
      // align-items: center;
      min-height: 100%;
      padding-top: 0;
      padding-bottom: em($toolbox-menu-height--between);

      & + & {
        border-top: 0 none;
      }
    }
  }
}
