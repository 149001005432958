.illustration-svg {
  &__svg-wrapper {
    $svg-width: 779;
    $svg-height: 949;
  
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: ($svg-height / $svg-width) * 100%;
    overflow: visible;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
  }

  .buttons {
    position: absolute;
    bottom: 0;
  }

  @include respond-to('desktop') {
    @include transition(max-width 1s linear, transform 1s linear);
    max-width: em(650px);
    transform: translateY(-10%);
    margin-left: auto;
    margin-right: auto;

    &--expanded {
      max-width: em(1000px);
      transform: translateY(3%);
    }
  }

  @include respond-to('desktop-large') {
    &--expanded {
      transform: translateY(-5%);
      
      .illustration-svg--offset-top {
        transform: translateY(-20%);
      }
    }
  }

  @include respond-to-height('laptop-between', $desktop: true) {
    max-width: em(450px);
  }

  @include respond-to-height('laptop', true) {
    

    &--expanded {
      max-width: em(800px);
    }
  }
}

