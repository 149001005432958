.heading-entity {
  background-color: $color__gray-soft;
  position: relative;
  z-index: $zindex__pageheading;
  &__logo {
    img {
      width:100%;
    }
  }
  &__label {
    @include font-weight('bold');

    display:block;
    line-height: 1.05;
    font-size: em($font-size__small);
    text-transform: uppercase;
    margin-bottom: 0.25em;
  }

  &__figure {
    $ratio: 318 / 580; //  width / height Image ratio
    @include bg-cover();

    width: 100%;
    padding-bottom: $ratio * 100%;
    background-position: 50%;
  }

  &__body {
    padding: em($spacing__sm) em($wrapper-padding) em($wrapper-padding);
  }

  &__meta {
    margin-bottom: em($spacing__gutter)
  }

  &__meta-item {
    @include font-weight('bold');
    line-height: 1.05;
    font-size: em($font-size__small);
    text-transform: uppercase;
  }

  &__location{
    text-transform: inherit;
  }

  &__meta-author {
    +a{
      color: $color__brand-aqua;
      &:hover {
        color: $color__gray;
      }
    }
    &:not(:last-child){
      &:after {
        content: ', ';
        color: $color__text;
      }
    }
    &:nth-last-child(2) {
      &:after {
        content: ', and ';
        color: $color__text;
      }
      &:first-child{
        &:after {
          content: ' and ';
          color: $color__text;
        }
      }
    }

  }

  @include respond-to('landscape') {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    flex-direction: row-reverse;
    justify-content: flex-end;

    &__figure {
      width: 40%;
      flex-shrink: 0;
      padding-bottom:0;
      &--no-image {
        width:20%;
      }
    }
  }

  @include respond-to('between') {
    &__body {
      padding: em($spacing__gutter) em($wrapper-padding--tablet); 
      min-height: em(320px);
      max-width: em($wrapper-max-width--very-narrow);
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      align-items: flex-end;
    }

    &__title {
      display: inline-block;
      max-width: em(600px, $font-size__display-3--tablet);
    }

    &__logo {
      width: 100%;
      img {
        width:100%;
      }
    }

    &__meta-author {
      font-size: em(18px);
      line-height: 1.2;
    }
  }

  @include respond-to('desktop-large') {
    &__figure {
      max-width: em(600px);
    }
  }
}
