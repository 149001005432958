.global-footer {
  background: $color__gunmetal;
  padding: em($spacing__module / 2) 0 em($spacing__module * 1.5);
  position: relative;
  color: $color__white;
  z-index: 1;

  &:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    top: calc(100% - 3.5em);
    left: 0;
    right: 0;
    background: $color__gunmetal url('./../images/cube-white.png')  0 0;
    opacity: 0.15;
  }

  &__menu {
    margin: em($spacing__module/2) 0 em($spacing__gutter) 0;
    padding: 1em 0;
    border: 1px solid $color__border--darker;
    border-width: 1px 0;
    &-link {
      position:relative;
    }
  }

  &__menu-option {
    & + & {
      margin-top: em($spacing__unit);
    }
  }

  &__smalltext {
    position:relative;
    font-size: em($font-size__smallest);
  }

  &__copyright {
    margin-top: em($spacing__sm, $font-size__smallest);
  }

  .social-links {
    margin-top: em($spacing__gutter);

    &__item {
      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }

  &__logo {
    max-width: em(110px);
  }

  @include respond-to('landscape') {
    &__columns {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between;
    
    }

    .social-links {
      margin-top: 0;

      .icon {
        font-size: em(20px);
      }

      &__link--boxed {
        border-color: $color__white;
      }
    }
  }
  @include respond-to('between') {
    .social-links__link:hover {
      color:$color__brand-orange;
    }
  }
  @include respond-to('desktop') {
    padding: em($spacing__md /2) 0 em($spacing__module--between);

    &__logo {
      max-width: em(210px);
    }

    &__menu {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      // justify-content: space-between;
    }

    &__menu-option {
      margin-right: 2em;
      & + & {
        margin-top: 0;
      }
    }
  }
  .underline {
    &--white {
      @include respond-to('between') {
        &:after {
          @include transition(width);
      
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom:0;
          width: 0%;
          height: rem(1px);
          background-color:#fff;
        }
      
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
      &:after {
        background-color: $color__white;
      }
    }
  }
}
