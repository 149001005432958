.workpath {
  $font-max-size: em(50px);
  padding-left: em($wrapper-padding);
  padding-right: em($wrapper-padding);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: em(680px);

  &__figure {
    max-width: em(200px);
    margin: 0 auto;
  }

  &__illustration {
    display: block;
    max-width: 100%;
  }

  &__copy {
    display: none;
  }

  &__cta {
    margin-top: 1em;
  }

  &__title {
    &--orange {
      color: $color__brand-orange;
    }
    &--purple {
      color: $color__brand-purple;
    }
  }
  
  @include respond-to('between') {
    padding-left: em($wrapper-padding--tablet);
    padding-right: em($wrapper-padding--tablet);
    width: 100%;
    
    &__figure {
      max-width: none;
      cursor: pointer;
    }

    &__copy {
      display: block;
      margin-top: 1em;
      padding-left: $wrapper-padding;
      padding-right: $wrapper-padding;
    }
    
    &__title {
      // font-size: 3.5vw;
      line-height: 1.1;
      cursor: pointer;
    }
    
    &__cta {
      margin-top: $spacing__sm;
    }
  }

  @include respond-to('desktop') {
    &__figure {
      opacity: 0;
      margin-bottom: 1em;
    }
    &__title {
      @include fluid-type($font-max-size)
    }
  }

  @include respond-to-height('laptop-between', $desktop: true) {
    &__figure {
      max-width: em(450px);
      margin: 0 auto;
    }

    &__title {
      font-size: 4.5vh;
      line-height: 1.1;
    }
  }
}
