
// Media queries breakpoints
// --------------------------------------------------
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
// Extra small screen / phone
$screen-xs:                  550px !default;
// Small screen / tablet
$screen-sm:                  768px !default;
// Medium screen / desktop
$screen-md:                  992px !default;
// Large screen / wide desktop
$screen-lg:                  1300px !default;
//additional sizes
$screen-xl:                  1600px !default;
$screen-xxl:                 1920px !default;

//modules


$breakpoints: (
  'landscape':  px-to-em($screen-xs), // 480px
  'between':  px-to-em($screen-sm), //768px
  'desktop': px-to-em($screen-md), //992px
  'desktop-large':  px-to-em($screen-lg),
  
  //to be used with max
  'between-max':  px-to-em($screen-sm - 1px) //767px
) !default;

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @media screen and (min-width: px-to-em($device-width, $font-size-base)) {
      @content;
    }
  }
}

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to-max($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media screen and (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @media screen and (max-width: px-to-em($device-width, $font-size-base)) {
      @content;
    }
  }
}



$breakpoints-vertical: (
  'landscape-mobile':  px-to-em(500px),
  'laptop':  px-to-em(800px),
  'laptop-slim':  px-to-em(720px),
  'compact-laptop':  px-to-em(570px),
  'laptop-between':  px-to-em(850px)
) !default;

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to-height($breakpoint, $desktop: false) {
  // If the key exists in the map
  @if map-has-key($breakpoints-vertical, $breakpoint) {
    // Prints a media query based on the value
    
    @if $desktop == true  {
      @media screen and (max-height: map-get($breakpoints-vertical, $breakpoint)) and (min-width: em(980px) ) {
        @content;
      }
    } else {
      @media screen and (max-height: map-get($breakpoints-vertical, $breakpoint)) {
        @content;
      }
    }
  }

  // If the key doesn't exist in the map
  @else {
    @media screen and (max-height: px-to-em($device-width, $font-size-base)) {
      @content;
    }
  }
}
