.searchbox {
  $size__searchicon: 20px;
  $spacing__search: 16px;

  position: relative;

  &__input {
		@include font-weight('regular');

    font-size: em($font-size__search);
  	line-height: 1.25;
		width: 100%;
		border: thin solid $color__gray-light;
		border-width: 0 0 thin;
		color: $color__gunmetal;
		padding: em($spacing__search, $font-size__search) em($wrapper-padding, $font-size__search);
		padding-right: em($wrapper-padding, $font-size__search) + em($size__searchicon * 2, $font-size__search);
		outline: none;
		background-color: $color__gray-light;

		&::placeholder {
			color: $color__gunmetal;
			opacity: 1;
		}
  }

  &__button {
  	position: absolute;
		right: em($wrapper-padding);
		top: 0;
		height: 100%;
		text-align: center;
		background-color: transparent;
		border-color: transparent;
    color:  $color__gunmetal;
    
    &:before {
      font-size: em($size__searchicon);
    }
	}


	&--darkgray &{ 
		
		&__input{
			border-color:$color__gray-light;
			background-color: $color__gray-dim;
			color: $color__white;
			&::placeholder {
				color: $color__white;
			}
		}

		&__button {
			color: $color__white;
		}
	}

	// &--lightt & {
	// 	 &__input {
	// 		 border-bottom-color: $color__white;
	// 		 color: $color__white;

	// 		 &::placeholder {
	// 			color: $color__white;
	// 		 }
	// 	 }

	// 	 &__button {
	// 		 color: $color__white;
	// 	 }
	// }

	// &--small & {
	// 	&__input {
	// 		font-size: $fontsize__5;
	// 		padding: $spacing__search--small 0;
	// 		padding-right: $spacing__search--small + $size__searchicon--small;
	// 	}

	// 	&__button {
	// 		font-size: $size__searchicon--small;
	// 	}
	// }

	@include respond-to('between') {
    $size__searchicon: 28px;

		&__input {
      font-size: em($font-size__search--desktop);
      padding: em($spacing__search, $font-size__search--desktop) em($wrapper-padding, $font-size__search--desktop);
      padding-right: em($size__searchicon * 2, $font-size__search--desktop);
      border-bottom-color: $color__white;
		}

		&__button {
      
      &:before {
        font-size: em($size__searchicon);
      }
		}
		
		&--flushed-desktop &  {
			&__input {
				padding: em($spacing__search, $font-size__search--desktop) 0;
			}

			&__button {
				right: 0;
			}
		}
	}
}