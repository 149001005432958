.pageheading {
  @include themify($themes) {
    background-image: linear-gradient(to right, themed('colorGradientStart'), themed('colorGradientEnd'));

    &:after {
      background-image: linear-gradient(to right, themed('colorGradientStart'), rgba(themed('colorGradientStart'), 0));
    }
  }
}

.underline {
  @include themify($themes) {
    &:after {
      background-color: themed('brandColor');
    }
  }
 
}

.global-submenu {
  @include themify($themes) {
    &__link {
      &--is-active {
        color: themed('brandColor');
      }
      &:hover {
        color: themed('brandColor');
      }
      &:before {
        background-color: themed('brandColor');
      }
    }
  }
}

.sidenav {
  @include themify($themes) {
    &__link {
      &--active {
        color: themed('brandColor');

        &:before {
          background-color: themed('brandColor');
        }
      }
    }
  }
}

.rte {
  @include themify($themes) {
    a {
      color: themed('brandColor');
    }
  }
}

.tooltip__container {
  @include themify($themes) {
    .tooltip__link {
      color: themed('brandColor');
    }
  }
}

.brand__link,
.brand__link-label {
  @include transition(color);
  
  color: $color__brand-orange;

  @include themify($themes) {  
    color: themed('brandColor')
  }

  @include respond-to('between') {
    &:hover {
      color: $color__black;
    }
  }

}


.btn-ghost {
  @include themify($themes) {
    color: themed('brandColor');
    border-color: themed('brandColor');

    &--white {
      color: $color__white;
      border-color: rgba($color__white, 0.75);

      &:hover {
        background: $color__dark_green;
      }
    }

    &--gray {
      color: $color__black;
      border-color: rgba($color__black, 0.75);

      &:hover {
        background: $color__gray-athens;
      }
    }
  }
}

.search-filters {
  @include themify($themes) {
    &__btn {
      &:hover {
        color: $color__white;
      }
      
      &--is-active {
        color: $color__white;
      }
    }
  }
}

.btn-cta {
  @include themify($themes) {
    &--bg {
      background-color: themed('brandColor');
    }
    &--can-submit {
      background-color: themed('brandColor');
      color: $color__white;
    }
  }
}
.btn-form {
  @include themify($themes) {
    border-color: themed('brandColor');
    color: themed('brandColor');
    &--valid {
      color: $color__white;
      background-color: themed('brandColor');
    }
  }
}

@include respond-to('between') {
  .articles-listing {
    @include themify($themes) {
      &__link {
        &:hover {
          color: themed('brandColor');
        }
      }
    }
  }

  .btn-cta:not(.btn-cta--underline) {
    &:hover {
      background-color: $color__gunmetal;
      color: $color__white;
      border-color: transparent;
    }
  }

  .btn-cta__link--underline {
    @include themify($themes) {
      &:after {
        background-color: themed('brandColor');
      }
    }
  }

  .global-header {
    @include themify($themes) {
      &__menu-toggle {
        .theme-aqua & {
          &--dots {
            color: $color__brand-aqua,
          }
        }
        
        &--is-expanded,
        &--is-active {
        }
      }

      &:before {
        background-color: themed('brandColor');
      }

      &:hover {
        color: themed('brandColor');
      }
    }
  }

  .sidenav {
    @include themify($themes) {
      &__link {
        &--active {
          color: themed('brandColor');

          &:after {
            background-color: themed('brandColor');
          }
        }
      }
    }
  }
}