@charset "UTF-8";
/* Variables */
@font-face {
  font-family: "workbench";
  src: url("./../icons/workbench.ttf") format("truetype"), url("./../icons/workbench.woff") format("woff"), url("./../icons/workbench.svg?z3e1ry#./../icons/") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "workbench" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-youtube:before {
  content: ""; }

.icon-linkedin:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-search-bold:before {
  content: ""; }

.icon-plus-bold:before {
  content: ""; }

.icon-minus-bold:before {
  content: ""; }

.icon-chevron-up--bold:before {
  content: ""; }

.icon-chevron-down--bold:before {
  content: ""; }

.icon-chevron-left--bold:before {
  content: ""; }

.icon-chevron-right--bold:before {
  content: ""; }

.icon-arrow-up--bold:before {
  content: ""; }

.icon-arrow-right--bold:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-share:before {
  content: ""; }

.icon-search:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-chevron-up:before {
  content: ""; }

.icon-chevron-down:before {
  content: ""; }

.icon-chevron-left:before {
  content: ""; }

.icon-chevron-right:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-long-arrow-up:before {
  content: ""; }

.icon-long-arrow-down:before {
  content: ""; }

.icon-graph:before {
  content: ""; }

.icon-folder:before {
  content: ""; }

.icon-dots:before {
  content: ""; }

.icon-bookmark:before {
  content: ""; }

.icon-mail:before {
  content: ""; }

.icon-bookmark-wide:before {
  content: ""; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden], template {
  display: none; }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html,
button,
input,
select,
textarea {
  font-family: sans-serif; }

body {
  margin: 0; }

a {
  background: transparent; }
  a:focus {
    outline: thin dotted; }
  a:hover, a:active {
    outline: 0; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

h2 {
  font-size: 1.5em;
  margin: 0.83em 0; }

h3 {
  font-size: 1.17em;
  margin: 1em 0; }

h4 {
  font-size: 1em;
  margin: 1.33em 0; }

h5 {
  font-size: 0.83em;
  margin: 1.67em 0; }

h6 {
  font-size: 0.75em;
  margin: 2.33em 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
strong {
  font-weight: bold; }

dfn {
  font-style: italic; }

mark {
  background: #ff0;
  color: #000; }

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em; }

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

q {
  quotes: "\201C" "\201D" "\2018" "\2019"; }

q:before, q:after {
  content: '';
  content: none; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 0; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0;
  white-space: normal; }

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

input::-ms-clear {
  display: none; }

button, input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
input[disabled] {
  cursor: default; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto;
  vertical-align: top; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }
  input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

html {
  font-size: 100%;
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit;
  outline: none; }

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font: 400 100% "proxima-nova";
  line-height: 1.5;
  color: #2D3539;
  overflow-x: hidden; }
  body.lock-page-scroll {
    height: 100vh;
    overflow: hidden; }
    body.lock-page-scroll main {
      position: static; }

main,
#app {
  display: block; }

a {
  color: inherit;
  text-decoration: none; }
  a:focus {
    outline: 1px dotted; }

ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

li {
  margin-bottom: 0; }

p,
blockquote, .blockquote {
  margin: 0 0 1.5em; }
  p:last-child,
  blockquote:last-child, .blockquote:last-child {
    margin-bottom: 0; }

h1, h2, h3, h4, h5, h6 {
  line-height: 1.2;
  margin: 0;
  font-weight: 700; }

fieldset {
  border: 0 none;
  padding: 0;
  margin: 0; }

address {
  font-style: normal; }

strong {
  font-weight: 700; }

.wrapper {
  max-width: 91.25em;
  padding-left: 1.375em;
  padding-right: 1.375em;
  margin-left: auto;
  margin-right: auto; }
  .wrapper--narrow {
    max-width: 56.25em; }
  .wrapper--very-narrow {
    max-width: 43.75em; }
  @media screen and (min-width: 48em) {
    .wrapper {
      padding-left: 1.875em;
      padding-right: 1.875em; } }
  @media screen and (min-width: 62em) {
    .wrapper {
      padding-left: 3.125em;
      padding-right: 3.125em; } }

.titles--h1 {
  font-weight: 600;
  font-size: 1.875em;
  line-height: 1.1; }
  @media screen and (min-width: 62em) {
    .titles--h1 {
      font-size: 2.25em; } }

.titles--h2 {
  font-weight: 700;
  font-size: 1.25em;
  line-height: 1.1; }
  @media screen and (min-width: 48em) {
    .titles--h2 {
      font-size: 1.375em; } }

.titles--h2-regular {
  font-weight: 700;
  font-size: 1.25em;
  line-height: 1.1;
  font-weight: 400; }
  @media screen and (min-width: 48em) {
    .titles--h2-regular {
      font-size: 1.375em; } }

.titles--h2-spaced {
  font-weight: 700;
  font-size: 1.25em;
  line-height: 1.1;
  margin-bottom: 0.5em; }
  @media screen and (min-width: 48em) {
    .titles--h2-spaced {
      font-size: 1.375em;
      margin-bottom: 0.5em; } }

.titles--h3 {
  font-weight: 400;
  line-height: 1.1;
  font-size: 1.125em; }
  @media screen and (min-width: 48em) {
    .titles--h3 {
      font-size: 1.375em; } }

.titles--h3-light {
  font-weight: 400;
  line-height: 1.1;
  font-size: 1.125em;
  font-weight: 300; }
  @media screen and (min-width: 48em) {
    .titles--h3-light {
      font-size: 1.375em; } }

.titles--h4 {
  font-weight: 400;
  line-height: 1.2;
  font-size: 1em; }
  @media screen and (min-width: 48em) {
    .titles--h4 {
      font-size: 1.125em; } }

.titles--h4-light {
  font-weight: 400;
  line-height: 1.2;
  font-size: 1em;
  font-weight: 300; }
  @media screen and (min-width: 48em) {
    .titles--h4-light {
      font-size: 1.125em; } }

.titles--h4-semibold {
  font-weight: 400;
  line-height: 1.2;
  font-size: 1em;
  font-weight: 600; }
  @media screen and (min-width: 48em) {
    .titles--h4-semibold {
      font-size: 1.125em; } }

.titles--h5 {
  font-weight: 700;
  line-height: 1.2;
  font-size: 0.875em; }

.titles--display1 {
  font-weight: 700;
  font-size: 2.5em;
  line-height: 1.2; }
  @media screen and (min-width: 48em) {
    .titles--display1 {
      font-size: 3.375em; } }
  @media screen and (min-width: 62em) {
    .titles--display1 {
      font-size: 4em; } }

.titles--display2 {
  font-weight: 600;
  font-size: 2.125em;
  line-height: 1.05; }
  @media screen and (min-width: 48em) {
    .titles--display2 {
      font-size: 3em; } }

.titles--display2-light {
  font-weight: 600;
  font-size: 2.125em;
  line-height: 1.05;
  font-weight: 300; }
  @media screen and (min-width: 48em) {
    .titles--display2-light {
      font-size: 3em; } }

.titles--display3 {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.2; }
  @media screen and (min-width: 48em) {
    .titles--display3 {
      font-size: 1.375em; } }

.titles--display3-light {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.2;
  font-weight: 300; }
  @media screen and (min-width: 48em) {
    .titles--display3-light {
      font-size: 1.375em; } }

.titles--display4 {
  font-weight: 600;
  font-size: 1.25em;
  line-height: 1.2; }
  @media screen and (min-width: 48em) {
    .titles--display4 {
      font-size: 1.5em; } }

.titles--eyebrow {
  font-weight: 700;
  font-size: 0.875em;
  text-transform: uppercase;
  line-height: 1.05; }

button {
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  line-height: 1.1;
  background-color: transparent;
  border-color: transparent;
  position: relative;
  border-radius: 0;
  padding: 0;
  border-width: 1px;
  font-family: "proxima-nova";
  overflow: visible; }

.btn {
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  position: relative;
  display: inline-block;
  background-color: transparent;
  border-radius: 0;
  border: 0 none;
  text-align: center; }
  .btn:focus {
    outline: 1px dotted; }
  .btn--disabled {
    color: #B5B5B5 !important;
    border-color: #B5B5B5 !important;
    cursor: inherit !important; }
    .btn--disabled:hover {
      background-color: inherit !important; }

.btn-ghost {
  color: inherit;
  padding: 0.83333em 1.25em;
  line-height: 1.4;
  min-width: 14.0625em;
  border: 1px solid #2D3539 #B5B5B5;
  font-size: 1.125em;
  font-weight: 600; }
  .btn-ghost--thick {
    border-width: 2px; }
  .btn-ghost--full {
    width: 100%; }
  .btn-ghost--center {
    text-align: center; }
  .btn-ghost--orange {
    color: #F7941D;
    border-color: rgba(247, 148, 29, 0.75); }
    .btn-ghost--orange:hover {
      border-color: #D14925; }
  .btn-ghost--purple {
    color: #79478B;
    border-color: rgba(121, 71, 139, 0.75); }
    .btn-ghost--purple:hover {
      border-color: #79478B; }
  .btn-ghost--with-chevron {
    text-align: left;
    padding-left: 1.25em;
    padding-right: 3.125em;
    position: relative; }
    .btn-ghost--with-chevron:after {
      display: inline-block;
      font-family: "workbench";
      font-size: inherit;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.25em; }
  .btn-ghost--clearall {
    font-size: 0.875em;
    padding: 0.47619em 0.71429em;
    min-width: inherit; }
  .btn-ghost--small {
    font-size: 0.875em;
    padding: 0.47619em 0.71429em;
    min-width: inherit; }

.btn-cta {
  font-weight: 600;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  color: #F7941D;
  display: inline-block;
  font-size: 1.125em; }
  .btn-cta--small {
    width: 100%; }
  .btn-cta--full {
    display: block; }
  .btn-cta--bg {
    position: relative;
    padding: 0.75em 2em 0.75em 1em;
    background-color: #F7941D;
    color: #fff; }
    .btn-cta--bg:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.25em; }
  .btn-cta--purple-bg {
    background-color: #79478B; }
  .btn-cta--can-submit {
    color: #fff; }
  .btn-cta:after {
    display: inline-block;
    font-family: "workbench";
    font-size: inherit;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    font-size: 0.8em;
    vertical-align: middle;
    margin-left: 0.5em; }
  .btn-cta--underline:after {
    display: inline; }
  .btn-cta--underline-no-arrow:after {
    content: ""; }

.btn-form {
  font-weight: 600;
  border: 1px solid #F7941D;
  padding: 0.75em 3.125em 0.75em 1.25em;
  color: #F7941D; }
  .btn-form:after {
    display: inline-block;
    font-family: "workbench";
    font-size: inherit;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.25em; }
  .btn-form--valid {
    background-color: #F7941D;
    color: #fff; }

@media screen and (min-width: 48em) {
  .btn-ghost {
    font-size: 18px;
    padding: 0.74074em 1.11111em; }
    .btn-ghost--with-chevron {
      padding-left: 1.25em;
      padding-right: 3.125em; }
    .btn-ghost--clearall {
      font-size: 0.875em; }
    .btn-ghost--small {
      padding: 0.5em 0.83333em;
      min-width: inherit; }
  .btn-cta:hover:not(.btn-cta--underline) {
    color: #000; }
  .btn-cta--bg:hover {
    background-color: #2D3539;
    color: #fff; }
  .btn-cta--small {
    font-size: 1.125em;
    min-width: 17.77778em;
    width: auto; }
  .btn-cta--valuation {
    font-size: 1.125em;
    min-width: 6.66667em;
    width: auto;
    padding-top: .5em;
    padding-bottom: .5em; }
  .btn-cta--underline {
    position: relative; }
    .btn-cta--underline:after {
      content: "";
      display: inline; }
  .btn-cta__link--underline {
    position: relative; }
    .btn-cta__link--underline:after {
      transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 0%;
      height: 0.0625rem;
      background-color: #2D3539;
      margin-left: 0px; }
    .btn-cta__link--underline:hover:after {
      width: 100%; }
  .btn-cta__arrow:after {
    display: inline-block;
    font-family: "workbench";
    font-size: inherit;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    font-size: 0.8em;
    vertical-align: middle;
    margin-left: 0.5em; }
  .btn-cta__arrow--external:after {
    display: inline-block;
    font-family: "workbench";
    font-size: inherit;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    font-size: 0.8em;
    vertical-align: middle;
    margin-left: 0.5em;
    transform: rotate(-45deg); } }

.rte {
  line-height: 1.5; }
  .rte--top-padded {
    padding-top: 1em; }
  .rte--light {
    font-weight: 300; }
  .rte--large {
    font-size: 1.125em; }
  .rte--theme-dark {
    color: #fff; }
  .rte--small {
    font-size: 0.875em;
    line-height: 1.4; }
  .rte--lead {
    font-size: 1.125em;
    line-height: 1.4; }
  .rte > *:last-child {
    margin-bottom: 0; }
  .rte > *:first-child {
    margin-top: 0; }
  .rte a {
    color: #248ECB; }
  .rte ul {
    list-style-type: disc;
    padding-left: 1.1em;
    margin-bottom: 1.5em; }
    .rte ul ul {
      list-style-type: circle;
      margin-top: 0.75em; }
  .rte li {
    margin-bottom: 0.3125em; }
  .rte ol {
    padding-left: 1.1em;
    list-style-type: decimal;
    margin-top: 0.3125em;
    margin-bottom: 1.5em; }
    .rte ol ol {
      margin-top: 0.75em; }
  .rte blockquote,
  .rte .blockquote {
    font-weight: 300;
    font-size: 1.25em;
    line-height: 1.2;
    margin: 1.5em 0;
    padding-top: 0.8em;
    border-top: 3px solid #27B0B1; }
    .rte blockquote p,
    .rte .blockquote p {
      margin-bottom: 0.5em; }
    .rte blockquote cite,
    .rte blockquote .cite,
    .rte .blockquote cite,
    .rte .blockquote .cite {
      font-size: 0.7em;
      text-transform: uppercase; }
  .rte .blockquote {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px; }
  .rte cite,
  .rte .cite {
    font-weight: 700;
    font-style: normal;
    font-size: 0.875em;
    color: #868D91; }
  .rte h2,
  .rte .h2-size {
    font-size: 1.25em;
    margin-bottom: 0.5em; }
  .rte h3,
  .rte .h3-size {
    font-size: 1.125em;
    margin-bottom: 1.33333em; }
  .rte h4,
  .rte .h4-size {
    font-size: 1em;
    margin-bottom: 1.5em; }
  .rte h5,
  .rte .h5-size {
    font-size: 0.875em;
    margin-bottom: 1.71429em; }
  .rte h6,
  .rte .h6-size {
    font-size: 0.875em;
    margin-bottom: 1.71429em; }
  .rte img,
  .rte iframe {
    max-width: 100%; }
  .rte table {
    background-color: #F3F4F2;
    margin: 3em 0; }
    .rte table tr {
      border-bottom: 1px solid #B5B5B5; }
      .rte table tr:last-child {
        border-bottom: 0; }
    .rte table th, .rte table .th {
      font-weight: 400;
      background-color: #2D3539;
      font-size: 1.25rem;
      color: #fff;
      padding: 1.2em;
      text-align: left; }
      .rte table th:first-child, .rte table .th:first-child {
        width: 30%;
        text-align: left; }
    .rte table td {
      padding: 1.5em; }
      .rte table td:first-child {
        color: #868D91;
        text-align: left; }
  @media screen and (min-width: 48em) {
    .rte {
      font-size: 1.125em; }
      .rte--large {
        font-size: 1.25em; }
      .rte--lead {
        font-size: 1.25em; }
      .rte--small {
        font-size: 0.875em; }
      .rte a:hover {
        color: #9B9B9B; }
      .rte cite,
      .rte .cite {
        font-size: 0.875em; }
      .rte blockquote,
      .rte .blockquote {
        font-size: 1.66667em;
        margin-left: 2.66667em; }
        .rte blockquote cite,
        .rte blockquote .cite,
        .rte .blockquote cite,
        .rte .blockquote .cite {
          font-size: 0.5em; }
      .rte blockquote, .rte .blockquote p::before {
        content: '\201C'; }
      .rte blockquote, .rte .blockquote p::after {
        content: '\201D'; }
      .rte h2,
      .rte .h2-size {
        margin-bottom: 0.45455em; }
      .rte h3,
      .rte .h3-size {
        font-size: 1.375em;
        margin-bottom: 0.45455em; }
      .rte h4,
      .rte .h4-size {
        font-size: 1.125em;
        margin-bottom: 0.55556em; }
      .rte table th, .rte table .th {
        font-size: 1.125rem; }
      .rte table th:first-child, .rte table .th:first-child {
        width: 30%;
        padding: 1.2em;
        padding-left: 3.125rem;
        color: #fff; }
      .rte table td {
        padding: 1.2em;
        font-size: 1.125rem;
        vertical-align: top; }
        .rte table td:first-child {
          font-size: 1em;
          color: #868D91;
          text-align: left;
          line-height: 1.8;
          padding: 1.33333em;
          padding-left: 3.125rem; }
      .theme-aqua .rte a:hover, .theme-orange .rte a:hover, .theme-purple .rte a:hover {
        color: #9B9B9B; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.sr-invisible-offset::before {
  display: block;
  content: " ";
  margin-top: -150px;
  height: 150px;
  visibility: hidden; }

.global-header {
  height: 4.0625em; }
  .global-header__wrapper {
    position: fixed;
    z-index: 100;
    width: 100%;
    padding: 0 1.375em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: inherit;
    border-bottom: 2px solid #868D91; }
    .global-header__wrapper:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: #fff; }
  .global-header__block-left {
    flex-grow: 1; }
  .global-header__block-right {
    flex-shrink: 0; }
  .global-header__home {
    transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    transform: translateZ(0);
    font-size: 1em;
    display: block;
    width: 15.3125em;
    position: relative; }
  .global-header__logo {
    width: 100%; }
  .global-header__block-right {
    flex-grow: 0;
    flex-shrink: 0; }
  .global-header__menu {
    display: none;
    z-index: 2; }
  @media screen and (min-width: 48em) {
    .global-header {
      height: 6.875em; }
      .global-header--condensed .global-header__wrapper {
        height: 4.5em;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1, 1); }
      .global-header .hamburger {
        display: none; }
      .global-header__home {
        width: 15.3125em;
        transition: width .5s ease; }
      .global-header__wrapper {
        transition: height .35s linear;
        padding-left: 1.875em;
        padding-right: 0;
        border-bottom: 0 none;
        height: 6.875em; }
      .global-header__block-right {
        display: inline-flex;
        align-items: center;
        align-self: stretch;
        padding-right: 1.875em;
        padding-left: 1.875em;
        position: relative; }
      .global-header__menu {
        display: block; }
      .global-header--workbench-active .global-flyout--is-expanded {
        background-color: #191d1f; }
      .global-header__menu-toggle {
        font-weight: 700;
        font-size: 1.25em;
        text-transform: uppercase; }
        .global-header__menu-toggle + .global-header__menu-toggle {
          margin-left: 1.25em; }
        .global-header__menu-toggle--search {
          font-weight: 400;
          vertical-align: bottom; }
        .global-header__menu-toggle--dots {
          font-weight: 400;
          font-size: 1em; }
          .global-header__menu-toggle--dots .icon:before {
            font-size: 0.375em; }
        .global-header__menu-toggle:hover {
          color: #2D3539; }
          .global-header__menu-toggle:hover:before {
            width: 100%; }
        .global-header__menu-toggle--is-expanded:before, .global-header__menu-toggle--is-active:before {
          width: 100%; }
        .global-header__menu .global-header__menu-toggle--is-expanded.global-header__menu-toggle--orange, .global-header__menu .global-header__menu-toggle--is-active.global-header__menu-toggle--orange {
          color: #F7941D; }
        .global-header__menu .global-header__menu-toggle--is-expanded.global-header__menu-toggle--purple, .global-header__menu .global-header__menu-toggle--is-active.global-header__menu-toggle--purple {
          color: #79478B; }
        .global-header__menu .global-header__menu-toggle--is-expanded.global-header__menu-toggle--green, .global-header__menu .global-header__menu-toggle--is-active.global-header__menu-toggle--green {
          color: #84A20E; }
        .global-header__menu .global-header__menu-toggle--is-expanded.global-header__menu-toggle--blue, .global-header__menu .global-header__menu-toggle--is-active.global-header__menu-toggle--blue {
          color: #27B0B1; }
        .global-header__menu .global-header__menu-toggle--orange:before {
          background-color: #F7941D; }
        .global-header__menu .global-header__menu-toggle--orange:hover {
          color: #F7941D; }
        .global-header__menu .global-header__menu-toggle--green:before {
          background-color: #84A20E; }
        .global-header__menu .global-header__menu-toggle--green:hover {
          color: #84A20E; }
        .global-header__menu .global-header__menu-toggle--purple:before {
          background-color: #79478B; }
        .global-header__menu .global-header__menu-toggle--purple:hover {
          color: #79478B; }
        .global-header__menu .global-header__menu-toggle--aqua:before {
          background-color: #27B0B1; }
        .global-header__menu .global-header__menu-toggle--aqua:hover {
          color: #27B0B1; }
        .global-header__menu .global-header__menu-toggle--search:before {
          background-color: #696969; }
        .global-header__menu .global-header__menu-toggle--search:hover {
          color: #696969; }
        .global-header__menu-toggle--is-expanded .global-header__menu .global-header__menu-toggle--search {
          color: red; }
        .global-header__menu-toggle--aqua.global-header__menu-toggle--is-expanded .icon-dots {
          color: #27B0B1; } }
  @media screen and (min-width: 62em) {
    .global-header--workbench-active .global-header__wrapper {
      background-color: transparent; }
      .global-header--workbench-active .global-header__wrapper:before {
        background-color: inherit; }
    .global-header--workbench-active .global-header__menu-toggle {
      color: #fff; }
    .global-header__home {
      width: 20.9375em;
      transition: width .5s ease; }
    .global-header__wrapper {
      padding-left: 3.125em; }
    .global-header__block-right {
      padding-right: 3.125em; }
    .global-header__menu-toggle + .global-header__menu-toggle {
      margin-left: 1.75em; }
    .global-header__menu-toggle:before {
      transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      content: '';
      display: block;
      position: absolute;
      right: 0;
      width: 0%;
      height: 0.25rem;
      top: -0.4em;
      background-color: #2D3539; } }

.global-flyout {
  transition: transform 0.3s ease-in-out;
  background-color: #696969;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: -1;
  height: 100vh;
  transform: translateY(-100%); }
  .global-flyout--is-expanded {
    transform: translateY(0%); }
  .global-flyout__container {
    height: 100%;
    overflow: auto;
    padding-top: 4.0625em;
    padding-bottom: 3.75em; }
  .global-flyout__close {
    display: none; }
  .global-flyout__panels {
    border-top: 2px solid #B5B5B5; }
  .global-flyout__panel--search {
    position: absolute;
    bottom: 0;
    width: 100%; }
    .global-flyout__panel--search .searchbox__input {
      background-color: #2D3539; }
  @media screen and (min-width: 48em) {
    .global-flyout {
      z-index: 1;
      background-color: #ECECEC;
      transform: translateX(100%);
      visibility: hidden;
      transition: transform 0.3s ease-in-out, visibility 1ms ease-in-out 0.3s; }
      .global-flyout--is-expanded {
        transition: transform 0.75s cubic-bezier(0.165, 0.84, 0.44, 1);
        visibility: visible;
        transform: translateX(0%); }
      .global-flyout__panel {
        display: none;
        height: 100%; }
        .global-flyout__panel--is-active {
          display: block; }
        .global-flyout__panel--search {
          background-color: #696969;
          position: static;
          bottom: auto;
          padding: 3.125em 1.875em; }
          .global-flyout__panel--search .searchbox__input {
            background-color: transparent; }
      .global-flyout__panels {
        border-top: 0 none;
        height: 100%;
        background-color: #696969; }
      .global-flyout__container {
        padding-top: 6.875em;
        padding-bottom: 0; }
        .global-header--condensed .global-flyout__container {
          padding-top: 4.5em; }
      .global-flyout__actions {
        display: block;
        position: absolute; }
      .global-flyout__close {
        display: block;
        color: #fff;
        position: absolute;
        top: 8.75em;
        right: 1.875em; }
        .global-flyout__close .icon {
          display: block;
          font-size: 1.5em;
          padding-bottom: 0.1em; } }
  @media screen and (min-width: 62em) {
    .global-flyout__actions {
      width: 27.1875em; } }

.global-menu {
  color: #fff; }
  .global-menu--purple {
    background-color: #79478B; }
  .global-menu--orange {
    background-color: #F7941D; }
  .global-menu--green {
    background-color: #84A20E; }
  .global-menu:not(.global-menu--with-accordion) {
    padding-top: 24px;
    color: #000; }
  .global-menu--with-accordion {
    border-bottom: 1px solid #fff; }
  .global-menu__toggle {
    padding: 1.5em 1.375em;
    width: 100%;
    text-align: left;
    color: #fff;
    text-transform: uppercase; }
  .global-menu__toggle-title {
    font-weight: 700;
    font-size: 1.375em;
    display: block;
    color: inherit;
    padding-right: 1.125em;
    position: relative; }
    .global-menu__toggle-title .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      font-size: 0.72727em; }
      .global-menu__toggle-title .icon:before {
        transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        position: relative;
        display: inline-block; }
  .global-menu__toggle--is-expanded .global-menu__toggle-title .icon:before {
    transform: rotate(45deg); }
  .global-menu__links {
    padding: 0 1.375em 24px; }
  .global-menu__links-option + .global-menu__links-option {
    margin-top: 0.625em; }
  .global-menu__link {
    font-weight: 600;
    color: #fff;
    position: relative;
    font-size: 1.625em; }
  .global-menu__title {
    display: none; }
  .global-menu__slide-container {
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: height 0.3s ease-in-out, visibility 100ms linear 0.3s; }
    .global-menu__slide-container--is-expanded {
      visibility: visible;
      transition: height 0.3s ease-in-out; }
  @media screen and (min-width: 48em) {
    .global-menu .underline--white:after {
      transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 0%;
      height: 0.0625rem;
      background-color: #fff; }
    .global-menu .underline--white:hover:after {
      width: 100%; } }
  .global-menu .underline--white:after {
    background-color: #fff; }
  @media screen and (min-width: 48em) {
    .global-menu {
      text-align: left;
      height: 100%; }
      .global-menu--blue {
        background-color: #3D89B4; }
      .global-menu:not(.global-menu--with-accordion) {
        padding-top: 0; }
      .global-menu--with-accordion {
        border-bottom: 0 none; }
      .global-menu__slide-container {
        height: auto;
        overflow: visible;
        visibility: visible; }
      .global-menu__toggle {
        display: none; }
      .global-menu__link {
        font-weight: 300;
        color: #fff; }
      .global-menu__links {
        padding: 1.875em; } }

.global-submenu {
  position: fixed;
  width: 100%;
  height: 3.25em;
  background-color: #2D3539;
  z-index: 90; }
  .global-submenu__mobile-toggle {
    font-weight: 600;
    width: 100%;
    text-align: left;
    color: #fff;
    padding: 1em 0;
    text-transform: uppercase;
    padding-right: 1.25em;
    position: relative;
    color: #F7941D;
    background-color: #2D3539;
    position: relative;
    z-index: 5;
    padding-left: 1.375em;
    padding-right: 1.375em; }
    .global-submenu__mobile-toggle:after {
      display: inline-block;
      font-family: "workbench";
      font-size: inherit;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      right: 1.375em; }
  .global-submenu__container {
    height: 3.25em;
    display: none; }
  .global-submenu__list {
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: #2D3539;
    width: 100%;
    position: absolute;
    left: 0;
    transform: translateY(-100%);
    padding-left: 1.375em;
    padding-right: 1.375em; }
  .global-submenu__list-item + .global-submenu__list-item {
    padding-top: 1em; }
  .global-submenu__list-item:last-child {
    padding-bottom: 1em; }
  .global-submenu__link {
    font-weight: 600;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), font-size 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    color: #F7941D; }
    .global-submenu__link--is-active {
      color: #fff; }
  .global-submenu--expanded .global-submenu__mobile-toggle:after {
    transform: rotateX(180deg); }
  .global-submenu--expanded .global-submenu__list {
    transform: translateY(0%); }
  @media screen and (min-width: 48em) {
    .global-submenu {
      height: 4.5em;
      transition: transform .35s linear; }
      .global-submenu__container {
        height: 4.5em;
        display: block; }
        .global-header--condensed + .global-submenu__container .global-submenu {
          transform: translateY(-38px); }
      .global-submenu__mobile-toggle {
        display: none; }
      .global-submenu__list {
        transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        background-color: transparent;
        width: auto;
        position: relative;
        left: auto;
        bottom: auto;
        border-bottom: 0 none;
        transform: none;
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: flex-end;
        padding-left: 1.875em;
        padding-right: 1.875em; }
      .global-submenu__list-item + .global-submenu__list-item {
        padding-top: 0;
        padding-left: 3vw; }
      .global-submenu__list-item:last-child {
        border-bottom: 0 none;
        padding-bottom: 0; }
      .global-submenu__link {
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
        padding-top: 0.5em;
        position: relative;
        display: inline-block; }
        .global-submenu__link:before {
          transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          content: '';
          display: block;
          position: absolute;
          right: 0;
          width: 0%;
          height: 0.25rem;
          top: 0;
          background-color: #fff; }
        .global-submenu__link:hover:before {
          width: 100%; }
        .global-submenu__link--is-active {
          color: #F7941D; }
          .global-submenu__link--is-active:before {
            width: 100%;
            background-color: #F7941D; }
          .theme--orange .global-submenu__link--is-active {
            color: #F7941D; }
            .theme--orange .global-submenu__link--is-active:before {
              background-color: #F7941D; }
          .theme--aqua .global-submenu__link--is-active {
            color: #248ECB; }
            .theme--aqua .global-submenu__link--is-active:before {
              background-color: #248ECB; } }
  @media screen and (min-width: 62em) {
    .global-submenu__list {
      padding-left: 3.125em;
      padding-right: 3.125em; } }

.global-footer {
  background: #2D3539;
  padding: 1.5625em 0 4.6875em;
  position: relative;
  color: #fff;
  z-index: 1; }
  .global-footer:before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    top: calc(100% - 3.5em);
    left: 0;
    right: 0;
    background: #2D3539 url("./../images/cube-white.png") 0 0;
    opacity: 0.15; }
  .global-footer__menu {
    margin: 1.5625em 0 1.875em 0;
    padding: 1em 0;
    border: 1px solid #696969;
    border-width: 1px 0; }
    .global-footer__menu-link {
      position: relative; }
  .global-footer__menu-option + .global-footer__menu-option {
    margin-top: 0.625em; }
  .global-footer__smalltext {
    position: relative;
    font-size: 0.75em; }
  .global-footer__copyright {
    margin-top: 2em; }
  .global-footer .social-links {
    margin-top: 1.875em; }
    .global-footer .social-links__item:not(:last-child) {
      margin-right: 1em; }
  .global-footer__logo {
    max-width: 6.875em; }
  @media screen and (min-width: 34.375em) {
    .global-footer__columns {
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between; }
    .global-footer .social-links {
      margin-top: 0; }
      .global-footer .social-links .icon {
        font-size: 1.25em; }
      .global-footer .social-links__link--boxed {
        border-color: #fff; } }
  @media screen and (min-width: 48em) {
    .global-footer .social-links__link:hover {
      color: #F7941D; } }
  @media screen and (min-width: 62em) {
    .global-footer {
      padding: 1.5625em 0 3.125em; }
      .global-footer__logo {
        max-width: 13.125em; }
      .global-footer__menu {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center; }
      .global-footer__menu-option {
        margin-right: 2em; }
        .global-footer__menu-option + .global-footer__menu-option {
          margin-top: 0; } }
  @media screen and (min-width: 48em) {
    .global-footer .underline--white:after {
      transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      content: '';
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0%;
      height: 0.0625rem;
      background-color: #fff; }
    .global-footer .underline--white:hover:after {
      width: 100%; } }
  .global-footer .underline--white:after {
    background-color: #fff; }

.link {
  font-weight: 400;
  line-height: 1.2;
  font-size: 1em;
  font-weight: 700;
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1); }
  @media screen and (min-width: 48em) {
    .link {
      font-size: 1.125em; } }
  .link--blue {
    color: #248ECB; }
  @media screen and (min-width: 48em) {
    .link--blue:hover {
      color: #000; } }

.hamburger {
  display: inline-block;
  justify-content: center;
  align-content: center;
  backface-visibility: hidden;
  padding: 0; }
  .hamburger__buns {
    position: relative;
    display: block;
    width: 1.4375em;
    height: 1em;
    overflow: visible; }
  .hamburger__topping {
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), top 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: #D14925;
    height: 0.125em;
    display: block;
    position: absolute;
    width: 100%; }
    .hamburger__topping--middle {
      top: 0.4375em; }
    .hamburger__topping--bottom {
      top: 0.875em; }
  .hamburger--is-active .hamburger__topping--middle {
    opacity: 0; }
  .hamburger--is-active .hamburger__topping--top {
    transform: rotate(45deg);
    top: 50%; }
  .hamburger--is-active .hamburger__topping--bottom {
    transform: rotate(-45deg);
    top: 50%; }
  @media screen and (min-width: 48em) {
    .hamburger:hover .hamburger__topping {
      background-color: #2D3539; } }

.searchbox {
  position: relative; }
  .searchbox__input {
    font-weight: 400;
    font-size: 1.25em;
    line-height: 1.25;
    width: 100%;
    border: thin solid #ECECEC;
    border-width: 0 0 thin;
    color: #2D3539;
    padding: 0.8em 1.1em;
    padding-right: 3.1em;
    outline: none;
    background-color: #ECECEC; }
    .searchbox__input::placeholder {
      color: #2D3539;
      opacity: 1; }
  .searchbox__button {
    position: absolute;
    right: 1.375em;
    top: 0;
    height: 100%;
    text-align: center;
    background-color: transparent;
    border-color: transparent;
    color: #2D3539; }
    .searchbox__button:before {
      font-size: 1.25em; }
  .searchbox--darkgray .searchbox__input {
    border-color: #ECECEC;
    background-color: #696969;
    color: #fff; }
    .searchbox--darkgray .searchbox__input::placeholder {
      color: #fff; }
  .searchbox--darkgray .searchbox__button {
    color: #fff; }
  @media screen and (min-width: 48em) {
    .searchbox__input {
      font-size: 1.375em;
      padding: 0.72727em 1em;
      padding-right: 2.54545em;
      border-bottom-color: #fff; }
    .searchbox__button:before {
      font-size: 1.75em; }
    .searchbox--flushed-desktop .searchbox__input {
      padding: 0.72727em 0; }
    .searchbox--flushed-desktop .searchbox__button {
      right: 0; } }

.section--main {
  overflow-x: hidden; }

.section--dark-gray {
  background-color: #2D3539; }

.section--gray-light {
  background-color: #EFF0ED; }

.section--roomy + .section--roomy {
  margin-top: 3.125em; }

.section--module + .section--module {
  margin-top: 3.125em; }

.section--module-with-border + .section--module-with-border {
  margin-top: 2.5em;
  padding-top: 2.5em;
  border-top: 1px solid #696969; }

.section--bottom-border {
  margin-bottom: 3.125em;
  padding-bottom: 3.125em;
  border-bottom: 1px solid #696969; }

.section--padding {
  padding-top: 3.125em;
  padding-bottom: 3.125em; }

.section .section__title {
  margin-bottom: 0.5em; }

.section .section__description {
  margin-bottom: 1.875em; }

@media screen and (min-width: 48em) {
  .section--roomy + .section--roomy {
    margin-top: 7.5em; }
  .section--module + .section--module {
    margin-top: 3.125em; }
  .section--module-with-border + .section--module-with-border {
    margin-top: 1.25em;
    padding-top: 3.125em;
    border-top: 1px solid #696969; }
  .section--bottom-border {
    padding-bottom: 3.125em; }
  .section--padding {
    padding-top: 3.125em;
    padding-bottom: 3.125em; }
  .section .section__title {
    margin-bottom: 0.45455em; }
  .section .section__description {
    margin-bottom: 3.125em; } }

@media screen and (min-width: 62em) {
  .section__columns {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start; }
    .section__columns--empty-sidebar:before {
      flex-shrink: 0;
      content: '';
      display: block;
      width: 15em;
      height: 2em;
      flex-shrink: 0;
      margin-right: 1.875em; }
    .section__columns-sidebar {
      flex-shrink: 0;
      width: 15em;
      margin-right: 1.875em; }
      .section__columns-sidebar--wide {
        width: 18.75em;
        margin-right: 3.125em; }
    .section__columns-main {
      flex-grow: 1;
      max-width: 53.125em;
      flex: 1; } }

.section-content + .section-content {
  margin-top: 1.875em; }

.section-content__component + .section-content__component {
  margin-top: 1.5em; }

.section-content__component + .section-content__component--spaced,
.section-content__component--spaced + .section-content__component--spaced {
  margin-top: 3.125em; }

.section-content__ctalist-option + .section-content__ctalist-option {
  margin-top: 1em; }

.section-content__header {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #696969; }

@media screen and (min-width: 48em) {
  .section-content__component + .section-content__component {
    margin-top: 1.875em; }
  .section-content__component + .section-content__component--spaced,
  .section-content__component--spaced + .section-content__component--spaced {
    margin-top: 3.125em; }
  .section-content__ctalist {
    margin-top: 3.125em; } }

.content-block + .content-block {
  margin-top: 1.875em; }

.content-block__title {
  margin-bottom: 0.16667em; }

.content-block__cta-action {
  margin-top: 1.5em;
  text-align: right; }

.content-block__component {
  margin-top: 1.5em;
  padding-bottom: 1.5em;
  border-bottom: 1px solid #696969; }
  .content-block__component-border {
    border-top: 1px solid #B5B5B5; }
  .content-block__component--border-dark {
    padding-top: 1.5em;
    border-top: 1px solid #696969; }
  .content-block__component--border-none {
    border-top: none;
    border-bottom: none;
    margin-top: 0;
    padding-bottom: 0; }

.content-block__cta-link {
  font-weight: 300;
  position: relative;
  display: inline-block;
  padding-right: 1.75em; }
  .content-block__cta-link:after {
    display: inline-block;
    font-family: "workbench";
    font-size: inherit;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "";
    color: #F7941D;
    font-size: 1.125em;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    padding-top: 0.2em;
    padding-left: 1em; }

.content-block__cta-strong {
  color: #F7941D;
  display: inline-block; }

.content-block__infographic {
  display: flex;
  flex-wrap: nowrap; }
  .content-block__infographic-figure {
    width: 20%;
    flex-shrink: 0;
    align-self: center;
    margin-right: 1.5em; }
  .content-block__infographic-image {
    max-width: 100%; }

.content-block__video-figure {
  margin: 0.625em 0; }

.content-block__video-headline {
  font-size: 1.125em; }

@media screen and (min-width: 48em) {
  .content-block__cta-link {
    font-size: 1.125em; }
  .content-block__cta-action {
    margin-top: 2.5em; }
  .content-block__video-headline {
    font-size: 1.375em; } }

.linked-list__option {
  padding: 1em 0;
  padding-right: 1.75em;
  position: relative;
  border-bottom: 1px solid #B5B5B5; }
  .linked-list__option:first-child {
    border-top: 1px solid #B5B5B5; }

.linked-list__link {
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  color: #F7941D;
  display: inline-block; }

.linked-list--dark .linked-list__option {
  border-color: #696969; }

@media screen and (min-width: 48em) {
  .linked-list--dark .linked-list__link:after {
    color: #fff; }
  .linked-list__link {
    font-size: 1.125em; }
    .linked-list__link:hover {
      color: #D14925; }
    .linked-list__link:after {
      display: inline-block;
      font-family: "workbench";
      font-size: inherit;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      color: #B5B5B5;
      font-size: 1.125em; } }

.toolbox-menu {
  position: fixed;
  width: 100%;
  background-color: #2D3539;
  z-index: 20;
  bottom: 0;
  left: 0;
  z-index: 90;
  border-bottom: thin solid #fff; }
  .toolbox-menu__mobile-toggle {
    font-weight: 700;
    padding: 1em 0;
    text-transform: uppercase;
    color: #fff;
    background-color: #84A20E;
    position: relative;
    z-index: 5;
    width: 100%;
    height: 3.75em; }
    .toolbox-menu__mobile-toggle .icon {
      padding-left: 0.5em; }
      .toolbox-menu__mobile-toggle .icon:before {
        transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        position: relative;
        font-size: 0.75em;
        display: inline-block; }
  .toolbox-menu__list {
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: #EFF0ED;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .toolbox-menu__list-item {
    padding: 1em 1.375em; }
    .toolbox-menu__list-item + .toolbox-menu__list-item {
      border-top: 1px solid #fff; }
    .toolbox-menu__list-item:last-child {
      padding-bottom: 1em;
      border-bottom: 2px solid #fff; }
  .toolbox-menu__link {
    font-weight: 600;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), font-size 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    color: #84A20E; }
    .toolbox-menu__link--is-active {
      color: #fff; }
  .toolbox-menu--is-expanded .toolbox-menu__mobile-toggle .icon:before {
    transform: rotate(45deg); }
  .toolbox-menu--is-expanded .toolbox-menu__list {
    transform: translateY(-100%); }
  @media screen and (min-width: 48em) {
    .toolbox-menu {
      position: absolute; }
      .toolbox-menu__mobile-toggle {
        display: none; }
      .toolbox-menu__list {
        transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        width: auto;
        position: relative;
        left: auto;
        bottom: auto;
        padding: 0;
        border-bottom: 0 none;
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch; }
      .toolbox-menu__list-item {
        padding: 0;
        display: inline-flex;
        flex-direction: column;
        flex-grow: 1; }
        .toolbox-menu__list-item + .toolbox-menu__list-item {
          padding: 0;
          margin: 0;
          border-top: 0;
          border-left: 1px solid #fff; }
        .toolbox-menu__list-item:last-child {
          border-bottom: 0 none;
          padding-bottom: 0; }
        .toolbox-menu__list-item:first-child .toolbox-menu__link {
          background-color: #84A20E;
          color: #EFF0ED; }
      .toolbox-menu__link {
        font-weight: 300;
        transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        font-size: 1.125em;
        display: inline-flex;
        min-height: 3.88889em;
        align-items: center;
        text-align: center;
        justify-content: center;
        background-color: #2D3539;
        color: #fff; }
        .toolbox-menu__link:hover {
          background-color: #84A20E;
          color: #EFF0ED; } }
  @media screen and (max-height: 50em) and (min-width: 61.25em) {
    .toolbox-menu {
      top: 100%;
      bottom: auto; } }
  @media screen and (max-height: 50em) {
    .toolbox-menu else {
      top: 100%;
      bottom: auto; } }

.projectphase--theme-purple .linked-list__link {
  color: #ADB9E3; }
  .projectphase--theme-purple .linked-list__link:hover {
    color: #fff; }

.projectphase--theme-purple .content-block__cta-link:after,
.projectphase--theme-purple .content-block__cta-strong {
  color: #ADB9E3; }

.projectphase--theme-purple .scrollspy-nav__link--is-active {
  color: #79478B; }

.projectphase__effect-enter-active, .projectphase__effect-leave-active {
  transition: opacity .5s ease; }

.projectphase__effect-enter, .projectphase__effect-leave-active {
  opacity: 0; }

.projectphase__dummy {
  position: absolute;
  background-color: transparent; }

.projectphase__main {
  color: #fff;
  padding: 3.125em 0 1.875em;
  background-color: #2D3539; }

.projectphase__sticky-svg {
  display: none; }

.projectphase__sidebar {
  padding: 5.9375em 0 5em; }

.projectphase .scrollspy-nav {
  top: 4.0625em; }

.projectphase__cta {
  width: 100%;
  text-align: left;
  margin-top: 2em; }

@media screen and (min-width: 48em) {
  .projectphase__sidebar {
    padding: 10em 0 7.5em; }
  .projectphase__main {
    color: #fff;
    padding: 3.125em 0 3.125em; }
  .projectphase .scrollspy-nav {
    top: 6.875em; } }

@media screen and (min-width: 62em) {
  .projectphase {
    display: flex;
    /* Enter and leave animations can use different */
    /* durations and timing functions.              */ }
    .projectphase__effect-enter-active {
      transition: transform 1s ease-out;
      opacity: 1; }
      .projectphase__effect-enter-active .scrollspy-nav {
        transition: opacity 1s linear 0.5s;
        opacity: 1; }
      .projectphase__effect-enter-active .projectphase__main:before {
        transition: opacity 1s linear 1s;
        opacity: 1; }
    .projectphase__effect-leave-active {
      transition: transform .5s ease-in 0.1s;
      opacity: 1; }
      .projectphase__effect-leave-active .scrollspy-nav {
        transition: opacity 0.3s; }
      .projectphase__effect-leave-active .projectphase__main:before {
        transition: opacity 0.3s; }
    .projectphase__effect-enter, .projectphase__effect-leave-to {
      transform: translateX(100%); }
      .projectphase__effect-enter .scrollspy-nav, .projectphase__effect-leave-to .scrollspy-nav {
        opacity: 0; }
      .projectphase__effect-enter .projectphase__main:before, .projectphase__effect-leave-to .projectphase__main:before {
        opacity: 0; }
    .projectphase .scrollspy-nav {
      top: auto;
      transition: opacity 1s linear 0.5s; }
    .projectphase__sidebar {
      background-color: #fff;
      width: 50%;
      flex-shrink: 0;
      padding-top: 10em; }
    .projectphase__main {
      padding: 8.75em 0 3.125em;
      flex-grow: 1;
      position: relative;
      overflow: hidden; }
      .projectphase__main:before {
        content: '';
        height: 6.875em;
        display: block;
        position: fixed;
        top: 0;
        background-color: #2D3539;
        z-index: 10;
        right: 0;
        left: 50%;
        opacity: 1; }
    .projectphase .scrollspy-nav {
      position: fixed;
      padding-bottom: 3.125em; }
      .projectphase .scrollspy-nav--stick-bottom {
        position: absolute;
        bottom: 0; } }

@media screen and (min-width: 81.25em) {
  .projectphase__sidebar {
    padding-left: 1.875em; } }

.workpath {
  padding-left: 1.375em;
  padding-right: 1.375em;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  max-width: 42.5em; }
  .workpath__figure {
    max-width: 12.5em;
    margin: 0 auto; }
  .workpath__illustration {
    display: block;
    max-width: 100%; }
  .workpath__copy {
    display: none; }
  .workpath__cta {
    margin-top: 1em; }
  .workpath__title--orange {
    color: #F7941D; }
  .workpath__title--purple {
    color: #79478B; }
  @media screen and (min-width: 48em) {
    .workpath {
      padding-left: 1.875em;
      padding-right: 1.875em;
      width: 100%; }
      .workpath__figure {
        max-width: none;
        cursor: pointer; }
      .workpath__copy {
        display: block;
        margin-top: 1em;
        padding-left: 22px;
        padding-right: 22px; }
      .workpath__title {
        line-height: 1.1;
        cursor: pointer; }
      .workpath__cta {
        margin-top: 24px; } }
  @media screen and (min-width: 62em) {
    .workpath__figure {
      opacity: 0;
      margin-bottom: 1em; }
    .workpath__title {
      font-size: calc(1em + (24.5 - 19) * (100vw - 3.125em) / (800 - 400)); } }
  @media screen and (max-height: 53.125em) and (min-width: 61.25em) {
    .workpath__figure {
      max-width: 28.125em;
      margin: 0 auto; }
    .workpath__title {
      font-size: 4.5vh;
      line-height: 1.1; } }
  @media screen and (max-height: 53.125em) {
    .workpath else__figure {
      max-width: 28.125em;
      margin: 0 auto; }
    .workpath else__title {
      font-size: 4.5vh;
      line-height: 1.1; } }

.workpath-grid {
  transition: opacity 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
  padding-top: 4.0625em;
  position: absolute;
  width: 100%;
  opacity: 1;
  visibility: visible;
  transition-delay: 0.5s;
  z-index: 2; }
  .workpath-grid--deactivate {
    transition: opacity 0.5s ease-in-out, visibility 100ms linear 0.5s;
    visibility: hidden;
    opacity: 0; }
  .workpath-grid__column {
    padding: 1.5em 0 0; }
  @media screen and (min-width: 48em) {
    .workpath-grid {
      padding-top: 6.875em;
      display: flex;
      flex-wrap: wrap;
      height: 100vh; }
      .workpath-grid__column {
        width: 50%;
        display: inline-flex;
        min-height: 100%;
        padding-top: 0;
        padding-bottom: 4.375em; }
        .workpath-grid__column + .workpath-grid__column {
          border-top: 0 none; } }

.scrollspy-nav {
  padding-left: 1.375em;
  padding-right: 1.375em;
  width: 100%;
  z-index: 20; }
  .scrollspy-nav__list-item + .scrollspy-nav__list-item {
    padding-top: 1em; }
  .scrollspy-nav__link {
    font-weight: 700;
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    font-size: 1.125em;
    color: #2D3539;
    backface-visibility: hidden;
    will-change: transform;
    transform-origin: left; }
    .scrollspy-nav__link--is-active {
      color: #F7941D;
      transform: scale(1.3); }
  @media screen and (min-width: 62em) {
    .scrollspy-nav {
      width: auto;
      background-color: transparent; }
      .scrollspy-nav__list-item + .scrollspy-nav__list-item {
        padding-top: 1em; }
      .scrollspy-nav__list-item:last-child {
        border-bottom: 0 none; }
      .scrollspy-nav__link {
        font-size: 1.375em; }
        .scrollspy-nav__link:hover {
          transform: scale(1.3); }
        .scrollspy-nav__link--is-active {
          color: #F7941D;
          transform: scale(1.3); } }

.social-links__item {
  display: inline; }

.social-links__link {
  display: inline-block;
  font-family: "workbench";
  font-size: inherit;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block; }
  .social-links__link--boxed {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2D3539;
    width: 1.875em;
    height: 1.875em; }

.social-links .icon {
  vertical-align: middle; }

@media screen and (min-width: 48em) {
  .social-links__link--boxed {
    width: 3.125em;
    height: 3.125em; } }

.pageheading {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: #F79621;
  background-image: linear-gradient(to right, #F79621, #F67300);
  position: relative;
  padding: 1.875em 0;
  z-index: 11; }
  .pageheading:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("./../images/cube-white.png") 0 0;
    opacity: 0.15;
    z-index: 1; }
  .pageheading:after {
    content: '';
    background-image: linear-gradient(to right, #F79621, rgba(247, 150, 33, 0));
    display: block;
    position: absolute;
    width: 30%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 2; }
  .pageheading--background:after, .pageheading--background:before {
    content: none; }
  .pageheading__body {
    position: relative;
    z-index: 5; }
  .pageheading__heading {
    color: #fff; }
  .pageheading__description {
    margin-top: 1.25em;
    color: #fff; }
  @media screen and (min-width: 48em) {
    .pageheading {
      padding: 3.125em 0 3.125em; } }

.workbench-landing {
  min-height: 100vh;
  position: relative; }
  .workbench-landing__sticky-svg {
    transition: opacity 0.5s ease-in-out;
    z-index: -1;
    width: 100%;
    position: absolute;
    opacity: 0; }
    .workbench-landing__sticky-svg--exploded {
      opacity: 1;
      transition: opacity 0.5s ease-in-out 0.5s; }
  @media screen and (min-width: 34.375em) {
    .workbench-landing__sticky-svg {
      max-width: 21.875em;
      left: 50%;
      margin-left: -10.9375em; } }
  @media screen and (max-height: 31.25em) {
    .workbench-landing else {
      min-height: 40.625em; } }
  @media screen and (min-width: 34.375em) {
    .workbench-landing__sticky-svg {
      max-width: 21.875em;
      left: 50%;
      margin-left: -10.9375em; } }
  @media screen and (min-width: 62em) {
    .workbench-landing__sticky-svg {
      max-width: none;
      z-index: 1;
      opacity: 1;
      width: 50%;
      margin: 0;
      position: absolute;
      left: auto;
      transition: opacity 0.5s ease-in-out, left 1.5s ease-in-out; }
      .workbench-landing__sticky-svg--right-aligned {
        left: 50%; }
      .workbench-landing__sticky-svg--exploded {
        position: fixed; }
        .workbench-landing__sticky-svg--exploded.workbench-landing__sticky-svg--right-aligned {
          left: 0;
          top: 3.5vh; }
      .workbench-landing__sticky-svg--stick-bottom {
        position: absolute;
        bottom: 0;
        top: auto !important; }
      .workbench-landing__sticky-svg--fadeout {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s ease-in-out, visibility 0.01s linear 0.5s, left 1.5s ease-in-out; } }
  @media screen and (max-height: 53.125em) and (min-width: 61.25em) {
    .workbench-landing__sticky-svg {
      top: 1.5vh; }
      .workbench-landing__sticky-svg--stick-bottom {
        position: absolute;
        bottom: 0; } }
  @media screen and (max-height: 53.125em) {
    .workbench-landing else__sticky-svg {
      top: 1.5vh; }
      .workbench-landing else__sticky-svg--stick-bottom {
        position: absolute;
        bottom: 0; } }
  @media screen and (max-height: 50em) and (min-width: 61.25em) {
    .workbench-landing {
      min-height: calc(100vh + 4.375em); } }
  @media screen and (max-height: 50em) {
    .workbench-landing else {
      min-height: calc(100vh + 4.375em); } }

.sidenav {
  width: 100%;
  position: relative;
  z-index: 6;
  margin-bottom: 1.875em; }
  .sidenav__mobile-toggle {
    font-weight: 600;
    text-transform: uppercase;
    padding: 1em;
    padding-right: 1.25em;
    position: relative;
    color: #2D3539;
    background-color: #ECECEC;
    position: relative;
    z-index: 5;
    width: 100%;
    text-align: left;
    border: 0 none; }
    .sidenav__mobile-toggle:after {
      display: inline-block;
      font-family: "workbench";
      font-size: inherit;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      right: 1em;
      color: #F7941D; }
    .section--padding .sidenav__mobile-toggle:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 100%;
      background-color: #fff;
      height: 3.125em; }
  .sidenav__list {
    transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    background-color: #2D3539;
    width: 100%;
    position: absolute;
    left: 0;
    transform: translateY(-100%);
    padding-left: 1.375em;
    padding-right: 1.375em; }
  .sidenav__list-item {
    padding-top: 1em; }
    .sidenav__list-item:last-child {
      padding-bottom: 1em; }
  .sidenav__link {
    font-weight: 600;
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), font-size 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    color: #F7941D; }
    .sidenav__link--is-active {
      color: #fff; }
  .sidenav--is-expanded .sidenav__mobile-toggle:after {
    transform: rotateX(180deg); }
  .sidenav--is-expanded .sidenav__list {
    transform: translateY(0%); }
  .sidenav__heading {
    font-weight: 400;
    line-height: 1.1;
    font-size: 1.125em;
    font-weight: 700;
    margin-bottom: 1em;
    margin-top: 0.1em;
    display: none; }
    @media screen and (min-width: 48em) {
      .sidenav__heading {
        font-size: 1.375em; } }
  @media screen and (min-width: 48em) {
    .section--padding .sidenav__mobile-toggle:before {
      height: 3.125em;
      pointer-events: none; } }
  @media screen and (min-width: 62em) {
    .sidenav {
      width: auto;
      position: static;
      background-color: transparent;
      padding-bottom: 3.125em;
      z-index: 20; }
      .sidenav-nopad {
        padding-bottom: 0; }
      .sidenav__heading {
        margin-bottom: 1em;
        margin-top: 0.1em;
        display: block; }
      .sidenav__mobile-toggle {
        display: none; }
      .sidenav__list {
        transition: none;
        background-color: transparent;
        width: auto;
        position: relative;
        left: auto;
        bottom: auto;
        padding: 0;
        border-bottom: 0 none;
        transform: none; }
      .sidenav__list-item {
        padding-top: 0; }
        .sidenav__list-item + .sidenav__list-item {
          padding-top: 1.25em; }
        .sidenav__list-item:last-child {
          border-bottom: 0 none; }
      .sidenav__link {
        font-weight: 400;
        color: #2D3539;
        font-size: 1.125em;
        line-height: 1.2;
        position: relative; }
        .sidenav__link:after {
          transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
          content: '';
          display: block;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 0%;
          height: 0.0625rem;
          background-color: #2D3539; }
        .sidenav__link:hover:after {
          width: 100%; }
        .sidenav__link--active {
          color: #F7941D; }
          .sidenav__link--active:after {
            width: 100%; }
          .theme--orange .sidenav__link--active {
            color: #F7941D; }
            .theme--orange .sidenav__link--active:after {
              background-color: #F7941D; }
          .theme--aqua .sidenav__link--active {
            color: #27B0B1; }
            .theme--aqua .sidenav__link--active:after {
              background-color: #27B0B1; } }

.illustration-svg__svg-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 121.82285%;
  overflow: visible; }

.illustration-svg svg {
  position: absolute;
  top: 0;
  left: 0; }

.illustration-svg .buttons {
  position: absolute;
  bottom: 0; }

@media screen and (min-width: 62em) {
  .illustration-svg {
    transition: max-width 1s linear, transform 1s linear;
    max-width: 40.625em;
    transform: translateY(-10%);
    margin-left: auto;
    margin-right: auto; }
    .illustration-svg--expanded {
      max-width: 62.5em;
      transform: translateY(3%); } }

@media screen and (min-width: 81.25em) {
  .illustration-svg--expanded {
    transform: translateY(-5%); }
    .illustration-svg--expanded .illustration-svg--offset-top {
      transform: translateY(-20%); } }

@media screen and (max-height: 53.125em) and (min-width: 61.25em) {
  .illustration-svg {
    max-width: 28.125em; } }

@media screen and (max-height: 53.125em) {
  .illustration-svg else {
    max-width: 28.125em; } }

@media screen and (max-height: 50em) and (min-width: 61.25em) {
  .illustration-svg--expanded {
    max-width: 50em; } }

@media screen and (max-height: 50em) {
  .illustration-svg else--expanded {
    max-width: 50em; } }

.action-tools {
  padding: 1.875em;
  color: #fff;
  position: relative; }
  .action-tools:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #5E7F06;
    background-image: linear-gradient(to right, #84A20E 20%, #5E7F06);
    z-index: -1; }
  .action-tools__white {
    color: #2D3539; }
    .action-tools__white:before {
      background-color: inherit;
      background-image: none; }
  .action-tools__gray {
    color: #2D3539; }
    .action-tools__gray:before {
      background-color: #EFF0ED;
      background-image: none; }
  .action-tools__heading {
    font-weight: 700;
    margin-bottom: 1em; }
  .action-tools__cta {
    width: 100%;
    margin-top: 1.5em; }
  .action-tools__figure {
    font-size: 6.25em;
    line-height: 1;
    vertical-align: bottom;
    margin-top: 0.25em; }
    .action-tools__figure img {
      width: 150px;
      height: 150px; }
  .action-tools__caption {
    font-weight: 400;
    line-height: 1.1;
    font-size: 1.125em;
    font-weight: 300;
    line-height: 1.4; }
    @media screen and (min-width: 48em) {
      .action-tools__caption {
        font-size: 1.375em; } }
  .action-tools__grid-option + .action-tools__grid-option {
    margin-top: 3.125em; }
  @media screen and (min-width: 48em) {
    .action-tools {
      padding: 3.125em 3.125em 3.125em 0; }
      .action-tools:before {
        left: -1.875em;
        right: -1.875em;
        width: auto; }
      .action-tools__grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between; }
      .action-tools__grid-option {
        width: calc(50% - 0.9375em);
        padding-bottom: 4.625em;
        position: relative; }
        .action-tools__grid-option + .action-tools__grid-option {
          margin-top: 0; }
      .action-tools__cta {
        position: absolute;
        bottom: 0; } }
  @media screen and (min-width: 62em) {
    .action-tools:before {
      right: 0; } }

.related-insights__header {
  margin-bottom: 0.625em; }

.related-insights__cta {
  margin-top: 1.5em; }

@media screen and (min-width: 48em) {
  .related-insights__header {
    margin-bottom: 1.5em; }
  .related-insights__cta {
    margin-top: 1.875em; } }

.articles-listing__link {
  font-size: 1.25em; }

.articles-listing__option {
  border-bottom: 1px solid #696969;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  padding: 1.25em 0; }
  .articles-listing__option:first-child {
    border-top: 1px solid #696969; }

.articles-listing__figure {
  width: 10.3125em;
  margin-left: 1.25em;
  flex-shrink: 0; }
  .articles-listing__figure > img {
    max-width: 100%;
    display: block; }

.articles-listing__body {
  flex-grow: 1; }

.articles-listing__meta {
  margin-bottom: 0.9375em; }

.articles-listing__meta-item {
  line-height: 1.05; }

@media screen and (min-width: 48em) {
  .articles-listing__option:after {
    font-size: 1.625em; }
  .articles-listing__figure {
    width: 9.375em; }
  .articles-listing__figure + .articles-listing__body .articles-listing__link {
    max-width: 27.27273em; }
  .articles-listing__link {
    font-size: 1.375em;
    max-width: 31.81818em;
    display: inline-block; } }

@media screen and (min-width: 62em) {
  .articles-listing__link {
    font-size: 1.375em; }
  .articles-listing__figure {
    margin-left: 1.875em; } }

.related-tags__list-item {
  display: inline;
  margin-right: 0.5em; }
  .related-tags__list-item:not(:last-child):after {
    content: ', '; }

.related-tags__tag {
  color: #27B0B1;
  font-weight: 400; }

@media screen and (min-width: 48em) {
  .related-tags__tag {
    transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    color: #27B0B1; }
    .related-tags__tag:hover {
      color: #9B9B9B; } }

.promo-story {
  background-color: #EFF0ED; }
  .promo-story__label {
    display: block;
    margin-bottom: 2em; }
  .promo-story__cta {
    margin-top: 1.5em; }
  .promo-story__figure {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-bottom: 72%;
    background-position: 50%; }
  .promo-story__body {
    padding: 1.5em 1.375em 5em; }
  @media screen and (min-width: 34.375em) {
    .promo-story {
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch; }
      .promo-story__figure {
        width: 40%;
        flex-shrink: 0;
        padding-bottom: 0; } }
  @media screen and (min-width: 48em) {
    .promo-story__body {
      padding: 3.125em 1.875em 7.5em;
      min-height: 26.25em; }
    .promo-story__title {
      display: inline-block;
      max-width: 27.27273em; } }
  @media screen and (min-width: 81.25em) {
    .promo-story__figure {
      max-width: 37.5em; } }

.video-launch {
  position: relative;
  border: 1px solid #696969;
  border-width: 1px 0;
  padding: 1em 0; }
  .video-launch--basic-view {
    padding: 0;
    border: 0 none; }
  .video-launch__toggle {
    width: 100%; }
  .video-launch__tag {
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2D3539;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.875em;
    padding: 0.5em 1em; }
  .video-launch__image {
    max-width: 100%;
    width: 100%; }
  .video-launch__play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(209, 73, 37, 0.75);
    height: 3.125em;
    width: 3.125em;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .video-launch__play-icon:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-top: 0.5em solid transparent;
      border-left: 1.125em solid #fff;
      border-bottom: 0.5em solid transparent; }
  .video-launch__figure {
    position: relative;
    display: block; }
  .video-launch__title {
    margin-top: 1em;
    display: block; }
  .video-launch__iframe {
    max-width: 100%; }
  @media screen and (min-width: 48em) {
    .video-launch--basic-view .video-launch__toggle {
      display: block; }
    .video-launch--basic-view .video-launch__figure {
      width: 100%;
      margin-right: 0;
      max-width: none; }
    .video-launch__toggle {
      display: flex;
      flex-wrap: nowrap;
      text-align: left; }
    .video-launch__figure {
      flex-shrink: 0;
      width: 40%;
      max-width: 24.375em;
      margin-right: 1em; }
    .video-launch__title {
      flex-grow: 1;
      margin-top: 0; }
    .video-launch__play-icon {
      height: 3.75em;
      width: 3.75em; } }

.video-player {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 200;
  background-color: rgba(45, 53, 57, 0.75);
  transition: opacity 0.3s ease-in-out, visibility 100ms linear 0.3s; }
  .video-player--active {
    transition: opacity 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    opacity: 1;
    visibility: visible; }
  .video-player__container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 40em;
    max-height: 24.375em;
    width: 100%; }
    .video-player__container iframe, .video-player__container object, .video-player__container video {
      max-width: 100%; }
  .video-player__close {
    position: absolute;
    bottom: 100%;
    right: 0;
    color: #EFF0ED;
    margin-bottom: 1em; }
    .video-player__close:before {
      font-size: 1.875em; }
    .video-player__close:hover {
      color: #fff; }
  @media screen and (min-width: 48em) {
    .video-player__close:before {
      font-size: 3.125em; } }

.video-embed {
  position: relative;
  border-bottom: 1px solid #696969;
  border-width: 1px 0;
  padding: 1em 0; }
  .video-embed__player {
    max-width: 31.25em; }
    .video-embed__player-wrapper {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%;
      position: relative; }
    .video-embed__player iframe, .video-embed__player object {
      max-width: 100%;
      position: absolute;
      width: 100%;
      height: 100% !important; }
  .video-embed__title {
    margin-top: 1em;
    display: block; }
  .video-embed__cta {
    margin-top: 1.5em; }
  .video-embed--grid .video-embed__container {
    margin-bottom: 1.875em; }
  @media screen and (min-width: 48em) {
    .video-embed__container {
      display: flex;
      flex-wrap: nowrap; }
    .video-embed__player {
      flex-shrink: 0;
      width: 49%;
      max-width: 26.25em;
      margin-right: 1em; }
    .video-embed__title {
      flex-grow: 1;
      margin-top: 0; }
    .video-embed__cta {
      width: 100%;
      margin-top: 1.875em; }
    .video-embed--grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
    .video-embed--grid .video-embed__container {
      width: calc(50% - 0.5em);
      margin-bottom: 1em;
      flex-wrap: wrap; }
      .video-embed--grid .video-embed__container:first-child:last-child {
        flex-grow: 1;
        width: 100%; }
    .video-embed--grid .video-embed__player {
      width: 100%;
      margin-right: 0; }
    .video-embed--grid .video-embed__title {
      margin-top: 1em;
      font-size: 1.375em; } }

.subscribe-callout {
  border-top: 1px solid #696969;
  padding-top: 1.5em; }
  .subscribe-callout__heading {
    margin-bottom: 1.2em; }
  @media screen and (min-width: 48em) {
    .subscribe-callout {
      padding-top: 1.875em; } }

.crosslink-callout {
  border-top: 1px solid #696969;
  padding-top: 1.5em;
  display: flex;
  flex-wrap: nowrap; }
  .crosslink-callout + .crosslink-callout {
    margin-top: 1.875em; }
  .crosslink-callout__figure {
    width: 6.25em;
    flex-shrink: 0;
    margin-right: 1em;
    text-align: center; }
    .crosslink-callout__figure > img {
      max-width: 100%; }
  .crosslink-callout__body {
    flex-grow: 1; }
  .crosslink-callout__heading {
    font-weight: 700;
    display: block;
    line-height: 1.05;
    font-size: 0.875em;
    text-transform: uppercase;
    margin-bottom: 2em; }
  .crosslink-callout__title {
    margin-bottom: 1.2em; }
  .crosslink-callout__cta {
    margin-top: 1.5em; }
  @media screen and (min-width: 48em) {
    .crosslink-callout {
      padding-top: 1.875em; }
      .crosslink-callout__figure {
        width: 12.5em; } }

.client-brief .flip-card {
  margin-bottom: 1.25em; }

.client-brief__figure {
  display: inline-block;
  background-color: #EFF0ED; }

.client-brief__img {
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .client-brief__img--hidden {
    visibility: hidden;
    z-index: -1;
    position: relative; }

.client-brief__visit-site {
  display: inline-block;
  margin-top: 1.5em;
  position: relative; }

@media screen and (min-width: 48em) {
  .client-brief__figure {
    width: 100%;
    margin-right: 0;
    text-align: center; } }

.person-brief {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap; }
  .person-brief__figure {
    display: block;
    width: 30%;
    margin-right: 1em;
    flex-shrink: 0;
    position: relative; }
    .person-brief__figure:before {
      content: '';
      background-color: #EFF0ED;
      display: block;
      width: 100%;
      height: 100%;
      position: absolute; }
  .person-brief__read-more {
    font-weight: 600;
    position: relative;
    color: #27B0B1;
    margin-top: 0.5em;
    display: inline-block; }
    .person-brief__read-more:before {
      display: inline-block;
      font-family: "workbench";
      font-size: inherit;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      right: -12px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 12px; }
  .person-brief__read-more.underline:after {
    background-color: #27B0B1; }
  .person-brief__img {
    display: block;
    max-width: 100%;
    position: relative; }
  .person-brief__description {
    position: relative;
    flex-grow: 1; }
  .person-brief__location {
    color: #868D91; }
  .person-brief__name, .person-brief__location {
    margin-bottom: 0.5em; }
  @media screen and (min-width: 48em) {
    .person-brief {
      display: block;
      margin-left: auto;
      margin-right: auto; }
      .person-brief__figure {
        width: 100%;
        margin-right: 0;
        max-width: 17em;
        height: 17em; }
      .person-brief__figure + .person-brief__description {
        margin-top: 1em; } }
  @media screen and (min-width: 48em) {
    .person-brief__figure {
      max-width: 16.25em;
      height: 16.25em; } }

.landing-grid {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap; }
  .landing-grid__figure {
    display: block;
    width: 30%;
    margin-right: 1em;
    flex-shrink: 0;
    position: relative; }
    .landing-grid__figure:before {
      content: '';
      background-color: #EFF0ED;
      display: block;
      width: 100%;
      position: absolute; }
  .landing-grid__img {
    display: block;
    max-width: 100%;
    position: relative; }
  .landing-grid__description {
    flex-grow: 1; }
  .landing-grid__location {
    color: #868D91;
    margin-bottom: .5em; }
  .landing-grid__read-more:after {
    content: '>';
    margin-left: 0.25em; }
  @media screen and (min-width: 48em) {
    .landing-grid {
      display: block;
      margin-left: auto;
      margin-right: auto; }
      .landing-grid__figure {
        width: 100%;
        margin-right: 0; }
      .landing-grid__figure + .landing-grid__description {
        margin-top: 1em; } }

.grid__item + .grid__item {
  margin-top: 1.875em; }

@media screen and (min-width: 48em) {
  .grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.9375em;
    position: relative; }
    .grid__item {
      width: calc(50% - 1.875em);
      margin: 0 0.9375em 1.875em; }
      .grid__item:nth-child(n+3) {
        margin-top: 1.875em; }
      .grid__item + .grid__item {
        margin-top: 0; }
    .grid--with-lines .grid__item:nth-child(n+3) {
      margin-top: 3.75em; }
    .grid--with-lines .grid__item:nth-child(2n+3) {
      position: relative; }
      .grid--with-lines .grid__item:nth-child(2n+3):before {
        content: '';
        position: absolute;
        margin-top: -2.8125em;
        width: calc(200% + 1.875em);
        backface-visibility: hidden;
        border-top: 1px solid #696969; } }

@media screen and (min-width: 81.25em) {
  .grid__item {
    width: calc(33.333% - 1.875em);
    margin: 0 0.9375em 1.875em; }
    .grid__item:nth-child(n+3) {
      margin-top: 0; }
    .grid__item:nth-child(n+4) {
      margin-top: 1.875em; }
  .grid--with-lines .grid__item:nth-child(n+3) {
    margin-top: 0; }
  .grid--with-lines .grid__item:nth-child(2n+3) {
    position: static; }
    .grid--with-lines .grid__item:nth-child(2n+3):before {
      content: none; }
  .grid--with-lines .grid__item:nth-child(n+4) {
    margin-top: 3.75em; }
  .grid--with-lines .grid__item:nth-child(3n+4) {
    position: relative; }
    .grid--with-lines .grid__item:nth-child(3n+4):before {
      content: '';
      position: absolute;
      margin-top: -2.8125em;
      width: calc(300% + 3.75em);
      backface-visibility: hidden;
      border-top: 1px solid #696969; } }

.stickynav {
  width: inherit;
  margin-left: -1.375em;
  margin-right: -1.375em; }
  .stickynav__container {
    position: relative;
    padding-top: 0; }
  @media screen and (min-width: 48em) {
    .stickynav {
      margin-left: -1.875em;
      margin-right: -1.875em; } }
  @media screen and (min-width: 62em) {
    .stickynav {
      margin-left: 0;
      margin-right: 0; }
      .stickynav__container {
        padding-top: 3.125em; }
      .stickynav__trigger {
        width: inherit; }
        .stickynav__trigger--is-sticky {
          position: fixed;
          top: 14.5em; }
        .stickynav__trigger--is-bottom-stuck {
          position: absolute;
          top: auto;
          bottom: 0; } }

.search-landing__control, .search-landing__criteria {
  margin-bottom: 1.5em; }

.search-landing__radio {
  line-height: 1.05;
  text-transform: uppercase;
  color: #2D3539;
  line-height: 1.2;
  font-size: 20px;
  position: relative;
  cursor: pointer;
  color: inherit; }
  .search-landing__radio:after {
    transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 0.0625rem;
    background-color: #2D3539; }
  .search-landing__radio:hover:after {
    width: 100%; }
  .search-landing__radio input {
    margin-right: 0.5em; }

.search-landing input[type="radio"] {
  display: none; }
  .search-landing input[type="radio"]:checked + .search-landing__radio {
    color: #27B0B1; }
    .search-landing input[type="radio"]:checked + .search-landing__radio:after {
      width: 100%;
      background-color: #27B0B1; }

.search-landing__criteria {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center; }

.search-landing__criteria-all {
  flex-shrink: 0;
  margin-left: 0.5em; }

.search-landing__listing-item {
  border-bottom: thin solid #696969;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  padding: 1.25em 0;
  padding-right: 1em; }
  .search-landing__listing-item:first-child {
    border-top: thin solid #696969; }
  .search-landing__listing-item--no-chevron:after {
    content: none; }

.search-landing__results-message {
  border: 1px solid #696969;
  border-width: thin 0;
  padding: 1.875em 0; }

.search-landing__view-more {
  margin-top: 1.5em; }

.search-landing__listing-heading {
  padding-bottom: 1.25em; }

@media screen and (min-width: 48em) {
  .search-landing__view-more--centered {
    max-width: 14.0625em;
    margin-left: auto;
    margin-right: auto; }
  .search-landing__listing-item {
    padding-right: 2em; }
    .search-landing__listing-item:after {
      font-size: 1.625em; }
  .search-landing__control, .search-landing__criteria {
    margin-bottom: 1.875em; } }

.search-landing__view-more {
  margin-top: 1.875em; }

.search-landing__divider {
  display: inline-block;
  vertical-align: middle; }
  .search-landing__divider:after {
    content: '|';
    font-size: 1.5em;
    margin: 0 0.25em; }

.search-item {
  display: flex;
  flex-wrap: nowrap; }
  .search-item__link {
    font-size: 1.25em; }
    .search-item__link--aqua {
      color: #27B0B1; }
    .search-item__link--small {
      line-height: 1.2; }
  .search-item__figure {
    width: 10.3125em;
    margin-left: 1.25em;
    flex-shrink: 0; }
    .search-item__figure > img {
      max-width: 100%;
      display: block; }
  .search-item__body {
    flex-grow: 1; }
  .search-item__meta {
    margin-bottom: 0.9375em; }
  .search-item__meta-item {
    font-weight: 700;
    line-height: 1.05;
    font-size: 0.875em;
    text-transform: uppercase; }
  .search-item__description, .search-item__tag {
    margin-top: 0.5em; }
  @media screen and (min-width: 48em) {
    .search-item__figure {
      width: 9.375em; }
    .search-item__figure + .search-item__body .search-item__link {
      max-width: 19.09091em; }
    .search-item__link {
      font-size: 1.5625em;
      max-width: 31.81818em;
      display: inline-block; }
      .search-item__link--small {
        font-size: 1.375em; } }
  @media screen and (min-width: 62em) {
    .search-item__link {
      font-size: 1.375em .search-item__link --small;
        font-size-font-size: 1.375em; }
    .search-item__figure {
      margin-left: 1.875em; } }

.search-filters__list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 -0.5em; }

.search-filters__list-item {
  margin: 0.5em; }

.search-filters__btn {
  text-transform: none;
  border-width: 2px; }
  .search-filters__btn--is-active {
    color: #fff;
    border-color: #27B0B1;
    background-color: #27B0B1; }
    .theme-purple .search-filters__btn--is-active {
      background-color: #79478B; }
    .theme-orange .search-filters__btn--is-active {
      background-color: #F7941D; }

.search-filters__label {
  margin-bottom: 0.75em; }

@media screen and (min-width: 48em) {
  .search-filters__btn:hover {
    color: #fff;
    border-color: #27B0B1;
    background-color: #27B0B1; }
    .theme-purple .search-filters__btn:hover {
      background-color: #79478B; }
    .theme-orange .search-filters__btn:hover {
      background-color: #F7941D; } }

.heading-entity {
  background-color: #EFF0ED;
  position: relative;
  z-index: 11; }
  .heading-entity__logo img {
    width: 100%; }
  .heading-entity__label {
    font-weight: 700;
    display: block;
    line-height: 1.05;
    font-size: 0.875em;
    text-transform: uppercase;
    margin-bottom: 0.25em; }
  .heading-entity__figure {
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    padding-bottom: 54.82759%;
    background-position: 50%; }
  .heading-entity__body {
    padding: 1.5em 1.375em 1.375em; }
  .heading-entity__meta {
    margin-bottom: 1.875em; }
  .heading-entity__meta-item {
    font-weight: 700;
    line-height: 1.05;
    font-size: 0.875em;
    text-transform: uppercase; }
  .heading-entity__location {
    text-transform: inherit; }
  .heading-entity__meta-author + a {
    color: #27B0B1; }
    .heading-entity__meta-author + a:hover {
      color: #9B9B9B; }
  .heading-entity__meta-author:not(:last-child):after {
    content: ', ';
    color: #2D3539; }
  .heading-entity__meta-author:nth-last-child(2):after {
    content: ', and ';
    color: #2D3539; }
  .heading-entity__meta-author:nth-last-child(2):first-child:after {
    content: ' and ';
    color: #2D3539; }
  @media screen and (min-width: 34.375em) {
    .heading-entity {
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      flex-direction: row-reverse;
      justify-content: flex-end; }
      .heading-entity__figure {
        width: 40%;
        flex-shrink: 0;
        padding-bottom: 0; }
        .heading-entity__figure--no-image {
          width: 20%; } }
  @media screen and (min-width: 48em) {
    .heading-entity__body {
      padding: 1.875em 1.875em;
      min-height: 20em;
      max-width: 37.5em;
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      align-items: flex-end; }
    .heading-entity__title {
      display: inline-block;
      max-width: 27.27273em; }
    .heading-entity__logo {
      width: 100%; }
      .heading-entity__logo img {
        width: 100%; }
    .heading-entity__meta-author {
      font-size: 1.125em;
      line-height: 1.2; } }
  @media screen and (min-width: 81.25em) {
    .heading-entity__figure {
      max-width: 37.5em; } }

.figure-highlights {
  position: relative;
  border: 1px solid #696969;
  border-width: 1px 0;
  padding: 1em 0; }
  .figure-highlights__caption {
    font-weight: 300;
    margin-top: 1em;
    display: block; }
  .figure-highlights__img {
    max-width: 100%;
    display: block; }
    .figure-highlights__img + .figure-highlights__img {
      margin-top: 0.625em; }
  @media screen and (min-width: 34.375em) {
    .figure-highlights__grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center; }
    .figure-highlights__img {
      width: calc(50% - 0.3125em);
      margin-bottom: 10px; }
      .figure-highlights__img:first-child:last-child {
        width: auto; }
      .figure-highlights__img + .figure-highlights__img {
        margin-top: 0; }
    .figure-highlights__caption {
      margin-top: 0; } }

.page-tools {
  margin-bottom: 3.125em; }
  .page-tools__label {
    display: none;
    margin-bottom: 0.9375em; }
  .page-tools .social-links {
    display: flex;
    justify-content: center; }
    .page-tools .social-links__item {
      flex-grow: 1; }
    .page-tools .social-links__item + .social-links__item .social-links__link--boxed, .page-tools .social-links__item--bookmark .social-links__link--boxed {
      border-left-width: 0; }
    .page-tools .social-links__divider {
      width: 3.125em;
      height: 1px;
      background-color: #D6D7D8;
      margin: 15px 0;
      display: none; }
    .page-tools .social-links__link {
      color: #868D91;
      height: 3.125em; }
      .page-tools .social-links__link .icon {
        font-size: 1.2em; }
      .page-tools .social-links__link--boxed {
        border-color: #D6D7D8;
        width: 100%; }
      .page-tools .social-links__link:hover {
        color: rgba(134, 141, 145, 0.6); }
  @media screen and (min-width: 62em) {
    .page-tools {
      display: inline-block;
      text-align: center; }
      .page-tools__label {
        display: block; }
      .page-tools .social-links {
        display: block; }
        .page-tools .social-links__item {
          display: block; }
        .page-tools .social-links__divider {
          display: block; }
        .page-tools .social-links__link--boxed {
          width: 3.125em; }
        .page-tools .social-links__link .icon {
          font-size: 1.3em; }
        .page-tools .social-links__link .icon-mail {
          font-size: 1.5em; }
        .page-tools .social-links__item + .social-links__item .social-links__link--boxed {
          border-top: 0 none;
          border-left-width: 1px; }
        .page-tools .social-links__item--bookmark .social-links__link--boxed {
          border-left-width: 1px; } }

.profile-details {
  position: relative; }
  .profile-details__related-link {
    font-weight: 600; }
  .profile-details__link {
    color: #27B0B1;
    cursor: pointer; }
  .profile-details__list {
    margin-top: 1.875em; }
  .profile-details__list-option {
    display: flex; }
    .profile-details__list-option + .profile-details__list-option {
      margin-top: 0.625em; }
  .profile-details__visit {
    margin-top: 1.25em; }
  .profile-details__field {
    width: 7.5em;
    flex-shrink: 0;
    margin-right: 1em;
    line-height: 1.8em; }
    .profile-details__field:after {
      content: ':'; }
  .profile-details__definition {
    flex-grow: 1; }
  .profile-details .social-links {
    position: absolute;
    right: 0;
    margin: 2em; }
    .profile-details .social-links__item:not(:last-child) {
      margin-right: 1em; }
    .profile-details .social-links__link {
      color: #868D91; }
      .profile-details .social-links__link--boxed {
        border-color: #D6D7D8; }
      .profile-details .social-links__link:hover {
        color: rgba(134, 141, 145, 0.6); }
  @media screen and (min-width: 48em) {
    .profile-details__list {
      margin-top: 2.5em; }
    .profile-details__field {
      width: 8.57143em;
      margin-right: 1em;
      line-height: 2; }
    .profile-details__definition, .profile-details__link {
      font-size: 1.125em; } }

.dictionary-listing__option {
  border-bottom: 1px solid #696969;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  padding: 1.25em 0; }
  .dictionary-listing__option:first-child {
    border-top: 1px solid #696969; }

.dictionary-listing__category {
  width: 1.47059em;
  margin-right: 0.2em;
  flex-shrink: 0; }

.dictionary-listing__body {
  flex-grow: 1; }

.dictionary-listing__deal + .dictionary-listing__deal {
  margin-top: 1.5em; }

.dictionary-listing__description {
  margin-top: 0.5em; }

.dictionary-listing__meta-item {
  font-weight: 700;
  line-height: 1.05;
  font-size: 0.875em;
  text-transform: uppercase; }

@media screen and (min-width: 48em) {
  .dictionary-listing__deal + .dictionary-listing__deal {
    margin-top: 1.875em; } }

@media screen and (min-width: 48em) {
  .underline:after {
    transition: width 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0%;
    height: 0.0625rem;
    background-color: #fff; }
  .underline:hover:after {
    width: 100%; } }

.document-driver {
  padding-left: 0; }
  .document-driver__form {
    margin-top: 1.875em;
    display: flex;
    justify-content: left;
    flex-wrap: wrap; }
    .document-driver__form__input {
      margin-right: 4.6875em;
      margin-top: 0.625em;
      position: relative;
      box-sizing: content-box; }
      .document-driver__form__input:after {
        display: inline-block;
        font-family: "workbench";
        font-size: inherit;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        right: 1em;
        top: 65%;
        pointer-events: none; }
    .document-driver__form__label {
      display: block; }
    .document-driver__form__select {
      margin-top: 0.3125em;
      width: 18.75em;
      background: transparent;
      border: none;
      background-color: #ECECEC;
      -webkit-appearance: none;
      -webkit-border-radius: 0px;
      padding: 0.625em;
      text-align: left;
      padding-left: 1em;
      padding-right: 2em;
      position: relative; }
    .document-driver__form__button {
      display: inline-block;
      margin: 2.5em 0;
      position: relative; }
  .document-driver .btn-cta {
    font-size: 1.25em;
    color: #ECECEC; }
    .document-driver .btn-cta:hover:not(.btn-cta--active) {
      background-color: transparent;
      color: #ECECEC; }
    .document-driver .btn-cta--active {
      color: #84A20E; }

.toolbox__container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.875em;
  justify-content: space-between; }

.toolbox__item {
  width: calc(100% - 1.875em / 2);
  margin-bottom: 1.875em; }
  .toolbox__item__content__description {
    margin-bottom: 0.9375em; }
  .toolbox__item__image {
    margin-bottom: 0.9375em; }
    .toolbox__item__image img {
      height: 200px; }

@media screen and (min-width: 48em) {
  .toolbox_container {
    margin-top: 0; }
  .toolbox__item {
    width: calc(50% - 1.875em); } }

.readings__option {
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  padding: 1.25em 0;
  padding-right: 1em; }

.readings__figure {
  width: 7.8125em;
  margin-right: 1em;
  flex-shrink: 0; }
  .readings__figure > img {
    max-width: 100%;
    display: block; }

.readings__body {
  flex-grow: 1; }

.readings__meta-item {
  font-weight: 700;
  line-height: 1.05;
  font-size: 0.875em;
  text-transform: uppercase; }

.readings__title {
  font-weight: 700; }

.readings__description {
  font-size: 0.875em;
  margin-bottom: 2em; }

@media screen and (min-width: 48em) {
  .readings__option {
    padding-right: 2em; }
    .readings__option:after {
      font-size: 1.625em; }
  .readings__figure {
    width: 9.375em;
    margin-right: 1.5em; }
  .readings__figure + .readings__body .readings__link {
    max-width: 27.27273em; } }

.report-scenarios__header-container {
  display: flex;
  justify-content: space-between; }

.report-scenarios__notice {
  border: 2px dashed #B5B5B5;
  padding: 2.5em;
  color: #868D91; }
  .report-scenarios__notice-label {
    font-weight: 700;
    font-size: 1.25em;
    line-height: 1.1;
    font-weight: 400;
    font-style: italic; }
    @media screen and (min-width: 48em) {
      .report-scenarios__notice-label {
        font-size: 1.375em; } }

.report-scenarios__create {
  display: block;
  width: 100%;
  border: 2px dashed #B5B5B5;
  padding: 2.5em;
  height: 100%; }
  .report-scenarios__create-label:before {
    content: '+';
    font-weight: 300;
    font-size: 1.6em;
    line-height: 1;
    display: block;
    margin: 0 auto 0.5em;
    border: 1px solid #84A20E;
    width: 1em;
    height: 1em;
    border-radius: 50%; }

.report-scenarios__charts {
  background-color: #EFF0ED;
  margin: 0 -1.375em;
  padding: 3.125em 1.375em; }

.report-scenarios__charts-column + .report-scenarios__charts-column {
  margin-top: 3.125em; }

.report-scenarios__legend {
  display: flex;
  align-items: flex-end;
  padding-top: 1em; }
  .report-scenarios__legend-keys {
    font-weight: bold; }
    .report-scenarios__legend-keys .legend-circle::before {
      content: ' ';
      height: 15px;
      width: 15px;
      border-radius: 50%;
      margin-right: 0.25em;
      display: inline-block;
      position: relative;
      top: 5%; }
    .report-scenarios__legend-keys .legend-circle.legend-note::before {
      background: #3d89b4; }
    .report-scenarios__legend-keys .legend-circle.legend-option::before {
      background: #f7941d; }
    .report-scenarios__legend-keys .legend-circle.legend-preferred::before {
      background: #79478b; }
    .report-scenarios__legend-keys .legend-circle.legend-common::before {
      background: #84a20e; }
    .report-scenarios__legend-keys .report-scenarios__legend-column {
      padding-left: 0.5em;
      padding-right: 0.5em; }
  .report-scenarios__legend-percentages {
    flex: 1 1 auto;
    align-self: start;
    text-align: center;
    font-weight: bold; }
    .report-scenarios__legend-percentages.last-column .report-scenarios__legend-column {
      margin-right: 0; }
  .report-scenarios__legend-column {
    height: 3em;
    padding: 0.5em 0;
    margin-right: 0.25em;
    line-height: 2; }
    .report-scenarios__legend-column.column-number {
      line-height: 1;
      height: 2em; }
    .report-scenarios__legend-column:nth-of-type(2n) {
      background-color: #EFF0ED; }
    .report-scenarios__legend-column--scenario.report-scenarios__legend-column--scenario {
      background-color: #3F3F3F;
      color: white; }
    .report-scenarios__legend-column.chart-scenario__field {
      display: block;
      width: auto; }
      .report-scenarios__legend-column.chart-scenario__field .chart-scenario__field-radio {
        transform: scale(2); }
  .report-scenarios__legend--liquidation {
    padding-top: 0; }
    .report-scenarios__legend--liquidation .report-scenarios__legend-column {
      line-height: 1;
      font-size: 0.8em;
      height: 4em;
      padding: 1em 0.5em; }
      .report-scenarios__legend--liquidation .report-scenarios__legend-column.column-number {
        height: 2em; }
      .report-scenarios__legend--liquidation .report-scenarios__legend-column .report-scenarios__legend-percent {
        font-weight: normal; }

@media screen and (min-width: 48em) {
  .report-scenarios__charts {
    padding: 3.125em 1.875em;
    display: flex;
    margin-left: -1.375em;
    margin-right: -1.375em; }
  .report-scenarios__charts-column {
    width: 33.3333%;
    padding: 0 0.6875em; }
    .report-scenarios__charts-column--length2 {
      width: 66.666%; }
    .report-scenarios__charts-column--length3 {
      width: 100%; }
    .report-scenarios__charts-column + .report-scenarios__charts-column {
      margin-top: 0; }
  .report-scenarios__notice {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 0; }
    .report-scenarios__notice-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2.5em;
      width: 100%;
      text-align: center; }
  .report-scenarios__create {
    display: flex;
    height: 100%;
    padding: 2.5em;
    text-align: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-start; }
    .report-scenarios__create-label {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 19.54545em;
      width: 100%;
      text-align: center; } }

@media screen and (min-width: 62em) {
  .report-scenarios__charts {
    margin: 0;
    padding: 3.125em 2.5em; }
  .report-scenarios__legend-keys {
    flex: 0 1 22%; }
  .report-scenarios__legend-percentages {
    flex: 1 1 auto; }
  .report-scenarios__legend--liquidation .report-scenarios__legend-column {
    font-size: 1em; } }

.tabbed-scenarios {
  background-color: #EFF0ED;
  margin: 0 -1.375em; }
  .tabbed-scenarios__nav {
    display: flex;
    flex-wrap: nowrap; }
    .tabbed-scenarios__nav-option {
      width: 33.3333%;
      flex-grow: 1; }
      .tabbed-scenarios__nav-option--active {
        width: 40%; }
  .tabbed-scenarios__switch {
    font-weight: 600;
    font-size: 1.125em;
    width: 100%;
    display: block;
    background-color: #F7F7F6;
    color: #84A20E;
    padding: 0.5em 0.25em; }
    .tabbed-scenarios__switch--selected {
      background-color: #EFF0ED;
      color: #2D3539; }
    .tabbed-scenarios__switch--disabled {
      background-color: #FCFCFC;
      color: #B5B5B5;
      cursor: default; }
  .tabbed-scenarios__panels {
    padding: 3.125em 1.375em; }
  @media screen and (min-width: 48em) {
    .tabbed-scenarios {
      margin: 0; }
      .tabbed-scenarios__panels {
        padding: 3.125em; }
      .tabbed-scenarios__nav-option--active {
        width: 33.3333%; }
      .tabbed-scenarios__switch {
        font-size: 1.375em; }
        .tabbed-scenarios__switch:before {
          content: "+";
          display: inline-block;
          margin-right: -1em;
          font-size: 1.2em;
          line-height: 1;
          transform: translateX(-1.2em);
          vertical-align: middle; } }

.tooltip {
  position: absolute;
  width: 18.125em;
  z-index: 6;
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 100ms 0.3s; }
  .tooltip--show {
    transition: opacity 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    visibility: visible;
    opacity: 1; }
  .tooltip__wrapper {
    background-color: #fff;
    padding: 1.875em;
    position: relative;
    z-index: 5; }
  .tooltip:before {
    content: '';
    display: block;
    width: 1.25em;
    height: 1.25em;
    position: absolute;
    right: 1.5em;
    top: 0.1em;
    transform: rotate(45deg) translateY(-1em);
    box-shadow: 0px 0px 6px 0 rgba(0, 0, 0, 0.5);
    background-color: #fff; }
  .tooltip--right-chevron:before {
    right: auto;
    left: 1.5em; }
  .tooltip--center-chevron:before {
    right: 50%; }
  .tooltip__title {
    font-weight: 600;
    font-size: 0.875em;
    line-height: 1.1;
    margin-bottom: 0.5em; }
  .tooltip__container {
    position: relative; }
  .tooltip__subtip {
    z-index: 4; }
  .tooltip__link {
    font-weight: 600;
    text-decoration: underline;
    display: inline-block; }
    .tooltip__link:after {
      content: 'i';
      width: 1.09091em;
      height: 1.09091em;
      display: inline-block;
      font-size: 0.6875em;
      line-height: 1.3;
      background-color: #84A20E;
      border-radius: 50%;
      color: #fff;
      text-align: center;
      vertical-align: super;
      position: absolute; }
  .tooltip__button {
    position: absolute;
    top: 0.9375em;
    right: 0.9375em; }
  @media screen and (min-width: 34.375em) {
    .tooltip__link {
      display: inline;
      margin-right: 1em; }
    .tooltip:before {
      right: 100%;
      top: 3em;
      transform: rotate(45deg) translateY(-1em); }
    .tooltip--right-chevron:before {
      right: auto;
      left: 100%;
      transform: rotate(45deg) translateX(-1em); } }

.tool-header {
  display: flex;
  margin-bottom: 2em; }
  .tool-header__title {
    font-size: 22px; }
  .tool-header__description {
    font-size: 18px; }
  .tool-header__number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #000;
    text-align: center;
    vertical-align: middle;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    box-sizing: border-box;
    margin-right: 1em; }
  .tool-header__text {
    width: 95%;
    display: inline-block; }

.tool-input {
  margin: 0.625em 0px; }
  .tool-input__container {
    margin-top: 1.875em; }
  .tool-input__input {
    display: inline-block; }
  .tool-input__label {
    display: block;
    margin-bottom: 0.9375rem;
    margin-right: 0.3125rem; }
    .tool-input__label--small-grey {
      display: inline-block; }
  .tool-input__error {
    color: #D14925;
    font-size: 0.75em; }
  .tool-input--plain input {
    width: 100%;
    background-color: #EFF0ED;
    border: 1px solid transparent;
    padding: 0.3125em 0.625em; }
    .tool-input--plain input:focus {
      background-color: #fff;
      border: 1px solid #CFE73D; }
  .tool-input--grey input {
    width: 100%;
    background-color: #EFF0ED;
    border: 1px solid transparent;
    padding: 0.3125em 0.625em; }
    .tool-input--grey input:focus {
      background-color: #fff;
      border: 1px solid #CFE73D; }
  .tool-input__divider {
    padding-top: 1.875em;
    border-bottom: 1px solid #696969; }
  .tool-input--small-grey {
    display: inline-block; }
    .tool-input--small-grey input {
      width: 6.25em;
      background-color: #EFF0ED;
      border: 1px solid transparent;
      padding: 0.3125em 0.625em; }
      .tool-input--small-grey input:focus {
        background-color: #fff;
        border: 1px solid #CFE73D; }
  .tool-input--ornament {
    position: relative;
    display: flex;
    align-items: stretch; }
    .tool-input--ornament:before {
      color: #fff;
      font-size: 0.875em;
      padding: 5px 8px;
      content: "";
      background-color: #84A20E;
      display: inline-flex;
      align-items: center; }
    .tool-input--ornament input, .tool-input--ornament__input {
      padding: 0.3125em 0.625em;
      border: 1px solid transparent; }
      .tool-input--ornament input:focus, .tool-input--ornament__input:focus {
        border: 1px solid #84A20E; }
    .tool-input--ornament--dollar input {
      width: 9em; }
    .tool-input--ornament--dollar:before {
      content: "$"; }
    .tool-input--ornament--decimal input {
      width: 9em; }
    .tool-input--ornament--decimal:before {
      content: "%"; }
  @media screen and (min-width: 48em) {
    .tool-input--plain input {
      width: 20.375em; } }
  .tool-input--small-grey input {
    width: 6.25em; }

.capital-calculator-section__panel--padded {
  padding: 1.875em; }

.capital-calculator-section__panel--one {
  margin-top: 1.875em; }

.capital-calculator-section__panel__header--one {
  display: flex;
  align-items: flex-end; }

.capital-calculator-section__panel__header__label--one {
  margin-right: 1.25em;
  align-self: center;
  display: inline-block; }

.capital-calculator-section__panel__header__option__radio--one {
  padding: 0.4375em 0.4375em;
  flex-wrap: nowrap;
  display: flex;
  justify-content: middle;
  align-items: center; }
  .capital-calculator-section__panel__header__option__radio--one input {
    margin-right: 0.3125em;
    cursor: pointer; }
  .capital-calculator-section__panel__header__option__radio--one--active {
    background-color: #EFF0ED; }

.capital-calculator-section__panel__body {
  background-color: #EFF0ED;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  transition: height 0.3s ease-in-out, visibility 100ms linear 0.3s; }
  .capital-calculator-section__panel__body--is-expanded {
    visibility: visible;
    transition: height 0.3s ease-in-out; }

.tool-padding {
  padding-left: 3.5em; }

.hide {
  display: none; }

.opaque-overlay:after {
  content: " ";
  z-index: 12;
  display: block;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5); }

.valuation-figure__note {
  font-weight: 400;
  color: #868D91;
  font-style: italic;
  font-size: 1.125rem; }

.valuation-figure__label {
  margin-bottom: 0.25em;
  font-size: 1.125rem; }

.valuation-figure__value {
  font-weight: 400;
  line-height: 1.1;
  font-size: 1.125em;
  font-weight: 400; }
  @media screen and (min-width: 48em) {
    .valuation-figure__value {
      font-size: 1.375em; } }
  .valuation-figure__value--large {
    font-weight: 600;
    font-size: 1.875em;
    line-height: 1.1;
    font-weight: 300;
    font-weight: 3.1875em; }
    @media screen and (min-width: 62em) {
      .valuation-figure__value--large {
        font-size: 2.25em; } }

.valuation-input-submit {
  margin: 1.875em 0;
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.valuation-input-clear {
  margin-left: 1.5em;
  color: #D14925;
  text-decoration: underline #D14925;
  cursor: pointer; }

.valuation-grid {
  margin-top: 1em; }
  .valuation-grid__item + .valuation-grid__item {
    margin-top: 1.875em; }
  .valuation-grid__title {
    margin-bottom: 1em;
    font-weight: 600; }
  @media screen and (min-width: 48em) {
    .valuation-grid {
      display: flex;
      flex-wrap: wrap;
      align-content: stretch; }
      .valuation-grid__title {
        width: 100%; }
      .valuation-grid__item {
        width: 33.3333%;
        flex-grow: 1;
        margin-bottom: 2em; }
        .valuation-grid__item:last-child, .valuation-grid__item:nth-last-child(2), .valuation-grid__item:nth-last-child(3) {
          margin-bottom: 0; }
        .valuation-grid__item + .valuation-grid__item {
          margin-top: 0; } }

.formfields-grid__item + .formfields-grid__item {
  margin-top: 1.5em; }

@media screen and (min-width: 34.375em) {
  .formfields-grid {
    display: flex;
    flex-wrap: wrap;
    align-content: center; }
    .formfields-grid--halfs {
      justify-content: space-between; }
    .formfields-grid__item + .formfields-grid__item {
      margin-top: 0; }
    .formfields-grid--halfs .formfields-grid__item {
      width: 50%;
      margin-bottom: 1.875em; }
      .formfields-grid--halfs .formfields-grid__item:last-child, .formfields-grid--halfs .formfields-grid__item:nth-last-child(2) {
        margin-bottom: 0; }
    .formfields-grid--thirds .formfields-grid__item {
      width: 33.3333%;
      margin-bottom: 1.875em; }
      .formfields-grid--thirds .formfields-grid__item:last-child, .formfields-grid--thirds .formfields-grid__item:nth-last-child(2), .formfields-grid--thirds .formfields-grid__item:nth-last-child(3) {
        margin-bottom: 0; }
    .formfields-grid--column-spaced .formfields-grid__item {
      margin-right: 1.5em; }
      .formfields-grid--column-spaced .formfields-grid__item:last-child {
        margin-right: 0; }
    .formfields-grid__grid-item + .formfields-grid__grid-item {
      margin-top: 0; } }

.liquidation-calculator__radio-input input[type="radio"] {
  margin-right: 0.4375em; }

.liquidation-calculator__start-fresh {
  margin-bottom: 1.875em;
  display: flex;
  justify-content: flex-start; }
  .liquidation-calculator__start-fresh__text {
    display: inline-block; }
  .liquidation-calculator__start-fresh__clear {
    display: inline-block;
    color: #D14925;
    cursor: pointer;
    margin-left: 0.625em; }
  .liquidation-calculator__start-fresh--bold {
    font-weight: 700; }

.liquidation-calculator__section-one {
  margin-bottom: 3.5em; }

.liquidation-calculator__multiple-container__input-container {
  display: flex; }

.liquidation-calculator__multiple-container .tool-input__input {
  display: inline; }

.liquidation-calculator__multiple-container .tool-input__label {
  margin-bottom: 0px; }

.liquidation-calculator__multiple-container__label {
  vertical-align: top;
  display: inline-block; }

.liquidation-calculator__multiple-container__close {
  font-weight: 700;
  display: inline-block;
  padding: 0.3125em;
  cursor: pointer; }

.print-email__icon {
  width: 40px;
  cursor: pointer; }

@media screen and (min-width: 48em) {
  .print-email__icon {
    margin-right: 1.875em; } }

.contact-form--form {
  width: 100%;
  max-width: 40em; }
  .contact-form--form .form-input,
  .contact-form--form .form-textarea {
    width: 100%;
    min-width: calc(100% - 2rem - 4px); }
  .contact-form--form .form-input, .contact-form--form .form-textarea, .contact-form--form .form-select, .contact-form--form .form-button {
    padding: 1rem;
    font-size: 1rem;
    border: solid 1px black;
    margin-bottom: 1rem; }
    .contact-form--form .form-input.is-error, .contact-form--form .form-textarea.is-error, .contact-form--form .form-select.is-error, .contact-form--form .form-button.is-error {
      border-color: red; }
  .contact-form--form .form-row {
    padding-bottom: 1rem; }
  .contact-form--form .form-input {
    margin-bottom: 0; }
  .contact-form--form .form-textarea {
    resize: none;
    min-height: 20em; }

.holder-figure + .holder-figure {
  margin-top: 1.5em; }

.holder-figure__number {
  position: relative;
  padding-left: 0.75em; }
  .holder-figure__number:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    left: 0;
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    border-radius: 50%;
    background-color: #000;
    vertical-align: middle; }
  .holder-figure__number--green:before {
    background-color: #84A20E; }
  .holder-figure__number--purple:before {
    background-color: #79478B; }
  .holder-figure__number--orange:before {
    background-color: #F7941D; }
  .holder-figure__number--blue:before {
    background-color: #3D89B4; }

@media screen and (min-width: 48em) {
  .holder-figure + .holder-figure {
    margin-top: 1.875em; }
  .holder-figure__number {
    padding-left: 1em; }
    .holder-figure__number:before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      content: '';
      width: 0.81818em;
      height: 0.81818em; } }

.chart-scenario {
  text-align: center; }
  .chart-scenario__field {
    display: inline-block;
    width: 50%;
    text-align: center; }
    .chart-scenario__field:only-child {
      width: 100%; }
    .chart-scenario__field-label, .chart-scenario__field-radio {
      vertical-align: middle; }
    .chart-scenario__field-label {
      margin-left: 0.5em; }
  .chart-scenario__figures, .chart-scenario__dilution {
    text-align: left; }
  .chart-scenario__dilution {
    padding-top: 1.5em;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    border-top: 2px solid #B5B5B5; }
    .chart-scenario__dilution--override {
      margin-bottom: 2.5em;
      padding-top: 0px;
      margin-top: 0px;
      border-top: 0px; }
    .chart-scenario__dilution-figure {
      font-weight: 300;
      padding-top: 0.25em; }
  .chart-scenario__figures {
    display: flex;
    flex-wrap: wrap; }
    .chart-scenario__figures .holder-figure {
      margin-top: 1.5em;
      width: 50%; }
  .chart-scenario__wrapper {
    width: 100%;
    display: flex; }
  @media screen and (min-width: 48em) {
    .chart-scenario {
      max-width: 12.5em;
      margin-left: auto;
      margin-right: auto; }
      .chart-scenario__field-radio {
        vertical-align: baseline; }
      .chart-scenario__dilution {
        padding-top: 1.875em;
        margin-top: 1.5em; }
        .chart-scenario__dilution--override {
          padding-top: 0px;
          margin-top: 0px; }
      .chart-scenario__figures {
        display: block; }
        .chart-scenario__figures .holder-figure {
          margin-top: 1.875em;
          width: 100%; } }

.am-chart {
  width: 100%;
  height: 600px;
  margin-bottom: 30px;
  display: block;
  z-index: 10; }
  .am-chart--disabled {
    display: none;
    height: 0; }

.chart {
  position: relative;
  height: 14.0625em; }
  .chart__hairlines {
    position: absolute;
    width: 100%;
    height: 100%; }
  .chart__hairline {
    border-top: 1px solid #CCD4C9;
    height: 25%; }
    .chart__hairline:last-child {
      border-bottom: 1px solid #CCD4C9; }
    .chart__hairline:before, .chart__hairline:after {
      content: '';
      display: block;
      width: 100%;
      border-bottom: 1px solid #CCD4C9;
      height: 33.333%; }
  .chart__bars {
    width: 3.25em;
    margin: 0 auto;
    height: 100%;
    position: relative;
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-end; }
  .chart__bar {
    transition: "height" 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    width: 100%; }
    .chart__bar--green {
      height: 62%;
      border-left: 3.25em solid #84A20E; }
    .chart__bar--purple {
      height: 23%;
      border-left: 3.25em solid #79478B; }
    .chart__bar--orange {
      height: 10%;
      border-left: 3.25em solid #F7941D; }
    .chart__bar--blue {
      height: 5%;
      border-left: 3.25em solid #3D89B4; }
  @media screen and (min-width: 48em) {
    .chart {
      position: relative;
      height: 14.0625em; }
      .chart__hairline {
        border-top-width: 2px; }
        .chart__hairline:last-child {
          border-bottom-width: 2px; } }

.accordion {
  position: relative; }
  .accordion__heading {
    position: relative;
    padding-right: 1.65em;
    text-align: left; }
    .accordion__heading .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      color: #000; }
      .accordion__heading .icon:before {
        transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        position: relative;
        display: inline-block; }
    .accordion__heading--is-expanded .icon:before {
      transform: rotateX(180deg); }
  .accordion__body {
    overflow: hidden;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    position: relative;
    transition: height 0.3s ease-in-out, visibility 100ms linear 0.3s; }
    .accordion__body--is-expanded {
      visibility: visible;
      transition: height 0.3s ease-in-out; }
  .accordion--justified-heading .accordion__heading {
    width: 100%; }
  .accordion--rotate-x .accordion__heading--is-expanded .icon:before {
    transform: rotate(45deg); }

.flip-card {
  display: block;
  margin: 0 auto;
  color: #fff;
  position: relative; }
  .flip-card__frame {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    perspective: 62.5em; }
  .flip-card__front, .flip-card__back {
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    backface-visibility: hidden;
    background-color: #EFF0ED;
    z-index: 2; }
  .flip-card__front {
    z-index: 2;
    transform: rotateY(0); }
  .flip-card__back {
    transform: rotateY(180deg);
    background-color: #696969;
    padding: 1.5em;
    padding-right: 1.8125em;
    text-align: left;
    overflow: auto; }
  .flip-card__close {
    position: absolute;
    top: 1em;
    right: 1em;
    color: #fff; }
    .flip-card__close:after {
      display: inline-block;
      font-family: "workbench";
      font-size: inherit;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: ""; }
  .flip-card--hover .flip-card__front {
    transform: rotateY(-180deg); }
  .flip-card--hover .flip-card__back {
    transform: rotateY(0deg); }
  @media screen and (min-width: 48em) {
    .flip-card:hover .flip-card__front {
      transform: rotateY(-180deg); }
    .flip-card:hover .flip-card__back {
      transform: rotateY(0deg); } }

.topic-radio-filters {
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-bottom: 50px;
  margin-left: -1.375em;
  margin-right: -1.375em;
  padding: 0 1.375em 1.25em; }
  .topic-radio-filters--show-filter-groups {
    background-color: #F8F8F8; }
  .topic-radio-filters--show-filter-groups .topic-radio-filters__dropdown-heading {
    border-color: transparent transparent #B5B5B5;
    color: #2D3539;
    border-width: 1px; }
    .topic-radio-filters--show-filter-groups .topic-radio-filters__dropdown-heading:after {
      transform: translateY(-50%) rotateX(180deg); }
  .topic-radio-filters--show-filter-groups .topic-radio-filters__groups {
    visibility: visible;
    transition: height 0.3s ease-in-out; }
  .topic-radio-filters__groups {
    overflow: hidden;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    position: relative;
    transition: height 0.3s ease-in-out, visibility 100ms linear 0.3s; }
  .topic-radio-filters__heading {
    padding-bottom: 10px;
    border-bottom: 1px solid #B5B5B5;
    display: none; }
  .topic-radio-filters__dropdown-heading {
    transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    font-weight: 600;
    line-height: 1.4;
    padding: 0.83333em 1.25em;
    border: 2px solid #2D3539 #27B0B1;
    width: 100%;
    font-size: 1.125em;
    line-height: 1.1;
    text-align: left;
    padding-right: 2.5em;
    color: #27B0B1; }
    .topic-radio-filters__dropdown-heading:after {
      display: inline-block;
      font-family: "workbench";
      font-size: inherit;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.11111em; }
  .topic-radio-filters__dropdown-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%; }
  @media screen and (min-width: 62em) {
    .topic-radio-filters {
      padding: 1.25em;
      margin-left: -1.25em;
      margin-right: 0;
      background-color: #F8F8F8; }
      .topic-radio-filters__heading {
        display: block; }
      .topic-radio-filters__dropdown-heading {
        display: none; }
      .topic-radio-filters__groups {
        height: auto;
        visibility: visible;
        position: relative;
        overflow: visible; } }

.filter-radio-group {
  border-bottom: 1px solid #B5B5B5; }
  .filter-radio-group__heading {
    text-transform: uppercase;
    padding: 10px 0;
    display: inline-block;
    font-size: 1em; }
  .filter-radio-group__filter-label {
    font-size: 1em;
    padding-left: 2.28571em;
    display: inline-block;
    word-break: break-word;
    word-wrap: break-word;
    cursor: pointer;
    max-width: 100%; }
    .filter-radio-group__filter-label:before {
      transition: border 0.3s cubic-bezier(0.165, 0.84, 0.44, 1), background 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      content: '';
      position: absolute;
      left: 0;
      top: -0.1em;
      width: 22px;
      height: 22px;
      border: 1px solid #B5B5B5;
      background-color: #fff;
      border-radius: 50%;
      display: block; }
  .filter-radio-group__filter-radio {
    position: absolute;
    opacity: 0; }
  .filter-radio-group__filter-radio:checked + .filter-radio-group__filter-label:before {
    background-color: #27B0B1;
    border-color: #27B0B1; }
  .filter-radio-group__filter-option {
    position: relative;
    line-height: 1.25rem; }
    @media screen and (min-width: 62em) {
      .filter-radio-group__filter-option {
        line-height: 1.5rem; } }
    .filter-radio-group__filter-option + .filter-radio-group__filter-option {
      margin-top: 1em; }
  .filter-radio-group__filters-list {
    padding: 10px 0 24px; }

.error-modal {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: table;
  transition: all .3s ease; }
  .error-modal__wrapper {
    display: table-cell;
    vertical-align: middle; }
  .error-modal__container {
    width: 85%;
    margin: 0px auto;
    padding: 20px;
    background-color: #D14925;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
    color: #fff; }
    @media screen and (min-width: 48em) {
      .error-modal__container {
        width: 500px; } }
  .error-modal__body {
    margin: 20px 0; }
  .error-modal__close {
    float: right;
    cursor: pointer; }

.pages--homepage .global-header {
  position: absolute; }

@media screen and (min-width: 48em) {
  .pages--homepage .global-footer {
    margin-bottom: 0; } }

.theme-aqua .pageheading {
  background-image: linear-gradient(to right, #3D89B4, #27B0B1); }
  .theme-aqua .pageheading:after {
    background-image: linear-gradient(to right, #3D89B4, rgba(61, 137, 180, 0)); }

.theme-orange .pageheading {
  background-image: linear-gradient(to right, #F79621, #F67300); }
  .theme-orange .pageheading:after {
    background-image: linear-gradient(to right, #F79621, rgba(247, 150, 33, 0)); }

.theme-purple .pageheading {
  background-image: linear-gradient(to right, #79478B, #b89ec1); }
  .theme-purple .pageheading:after {
    background-image: linear-gradient(to right, #79478B, rgba(121, 71, 139, 0)); }

.theme-green .pageheading {
  background-image: linear-gradient(to right, #83A10F, #7B9A0C); }
  .theme-green .pageheading:after {
    background-image: linear-gradient(to right, #83A10F, rgba(131, 161, 15, 0)); }

.theme-aqua .underline:after {
  background-color: #27B0B1; }

.theme-orange .underline:after {
  background-color: #F7941D; }

.theme-purple .underline:after {
  background-color: #79478B; }

.theme-green .underline:after {
  background-color: #84A20E; }

.theme-aqua .global-submenu__link--is-active {
  color: #27B0B1; }

.theme-aqua .global-submenu__link:hover {
  color: #27B0B1; }

.theme-aqua .global-submenu__link:before {
  background-color: #27B0B1; }

.theme-orange .global-submenu__link--is-active {
  color: #F7941D; }

.theme-orange .global-submenu__link:hover {
  color: #F7941D; }

.theme-orange .global-submenu__link:before {
  background-color: #F7941D; }

.theme-purple .global-submenu__link--is-active {
  color: #79478B; }

.theme-purple .global-submenu__link:hover {
  color: #79478B; }

.theme-purple .global-submenu__link:before {
  background-color: #79478B; }

.theme-green .global-submenu__link--is-active {
  color: #84A20E; }

.theme-green .global-submenu__link:hover {
  color: #84A20E; }

.theme-green .global-submenu__link:before {
  background-color: #84A20E; }

.theme-aqua .sidenav__link--active {
  color: #27B0B1; }
  .theme-aqua .sidenav__link--active:before {
    background-color: #27B0B1; }

.theme-orange .sidenav__link--active {
  color: #F7941D; }
  .theme-orange .sidenav__link--active:before {
    background-color: #F7941D; }

.theme-purple .sidenav__link--active {
  color: #79478B; }
  .theme-purple .sidenav__link--active:before {
    background-color: #79478B; }

.theme-green .sidenav__link--active {
  color: #84A20E; }
  .theme-green .sidenav__link--active:before {
    background-color: #84A20E; }

.theme-aqua .rte a {
  color: #27B0B1; }

.theme-orange .rte a {
  color: #F7941D; }

.theme-purple .rte a {
  color: #79478B; }

.theme-green .rte a {
  color: #84A20E; }

.theme-aqua .tooltip__container .tooltip__link {
  color: #27B0B1; }

.theme-orange .tooltip__container .tooltip__link {
  color: #F7941D; }

.theme-purple .tooltip__container .tooltip__link {
  color: #79478B; }

.theme-green .tooltip__container .tooltip__link {
  color: #84A20E; }

.brand__link,
.brand__link-label {
  transition: color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  color: #F7941D; }
  .theme-aqua .brand__link, .theme-aqua
  .brand__link-label {
    color: #27B0B1; }
  .theme-orange .brand__link, .theme-orange
  .brand__link-label {
    color: #F7941D; }
  .theme-purple .brand__link, .theme-purple
  .brand__link-label {
    color: #79478B; }
  .theme-green .brand__link, .theme-green
  .brand__link-label {
    color: #84A20E; }
  @media screen and (min-width: 48em) {
    .brand__link:hover,
    .brand__link-label:hover {
      color: #000; } }

.theme-aqua .btn-ghost {
  color: #27B0B1;
  border-color: #27B0B1; }
  .theme-aqua .btn-ghost--white {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.75); }
    .theme-aqua .btn-ghost--white:hover {
      background: #62830a; }
  .theme-aqua .btn-ghost--gray {
    color: #000;
    border-color: rgba(0, 0, 0, 0.75); }
    .theme-aqua .btn-ghost--gray:hover {
      background: #D6D7D8; }

.theme-orange .btn-ghost {
  color: #F7941D;
  border-color: #F7941D; }
  .theme-orange .btn-ghost--white {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.75); }
    .theme-orange .btn-ghost--white:hover {
      background: #62830a; }
  .theme-orange .btn-ghost--gray {
    color: #000;
    border-color: rgba(0, 0, 0, 0.75); }
    .theme-orange .btn-ghost--gray:hover {
      background: #D6D7D8; }

.theme-purple .btn-ghost {
  color: #79478B;
  border-color: #79478B; }
  .theme-purple .btn-ghost--white {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.75); }
    .theme-purple .btn-ghost--white:hover {
      background: #62830a; }
  .theme-purple .btn-ghost--gray {
    color: #000;
    border-color: rgba(0, 0, 0, 0.75); }
    .theme-purple .btn-ghost--gray:hover {
      background: #D6D7D8; }

.theme-green .btn-ghost {
  color: #84A20E;
  border-color: #84A20E; }
  .theme-green .btn-ghost--white {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.75); }
    .theme-green .btn-ghost--white:hover {
      background: #62830a; }
  .theme-green .btn-ghost--gray {
    color: #000;
    border-color: rgba(0, 0, 0, 0.75); }
    .theme-green .btn-ghost--gray:hover {
      background: #D6D7D8; }

.theme-aqua .search-filters__btn:hover {
  color: #fff; }

.theme-aqua .search-filters__btn--is-active {
  color: #fff; }

.theme-orange .search-filters__btn:hover {
  color: #fff; }

.theme-orange .search-filters__btn--is-active {
  color: #fff; }

.theme-purple .search-filters__btn:hover {
  color: #fff; }

.theme-purple .search-filters__btn--is-active {
  color: #fff; }

.theme-green .search-filters__btn:hover {
  color: #fff; }

.theme-green .search-filters__btn--is-active {
  color: #fff; }

.theme-aqua .btn-cta--bg {
  background-color: #27B0B1; }

.theme-aqua .btn-cta--can-submit {
  background-color: #27B0B1;
  color: #fff; }

.theme-orange .btn-cta--bg {
  background-color: #F7941D; }

.theme-orange .btn-cta--can-submit {
  background-color: #F7941D;
  color: #fff; }

.theme-purple .btn-cta--bg {
  background-color: #79478B; }

.theme-purple .btn-cta--can-submit {
  background-color: #79478B;
  color: #fff; }

.theme-green .btn-cta--bg {
  background-color: #84A20E; }

.theme-green .btn-cta--can-submit {
  background-color: #84A20E;
  color: #fff; }

.theme-aqua .btn-form {
  border-color: #27B0B1;
  color: #27B0B1; }
  .theme-aqua .btn-form--valid {
    color: #fff;
    background-color: #27B0B1; }

.theme-orange .btn-form {
  border-color: #F7941D;
  color: #F7941D; }
  .theme-orange .btn-form--valid {
    color: #fff;
    background-color: #F7941D; }

.theme-purple .btn-form {
  border-color: #79478B;
  color: #79478B; }
  .theme-purple .btn-form--valid {
    color: #fff;
    background-color: #79478B; }

.theme-green .btn-form {
  border-color: #84A20E;
  color: #84A20E; }
  .theme-green .btn-form--valid {
    color: #fff;
    background-color: #84A20E; }

@media screen and (min-width: 48em) {
  .theme-aqua .articles-listing__link:hover {
    color: #27B0B1; }
  .theme-orange .articles-listing__link:hover {
    color: #F7941D; }
  .theme-purple .articles-listing__link:hover {
    color: #79478B; }
  .theme-green .articles-listing__link:hover {
    color: #84A20E; }
  .btn-cta:not(.btn-cta--underline):hover {
    background-color: #2D3539;
    color: #fff;
    border-color: transparent; }
  .theme-aqua .btn-cta__link--underline:after {
    background-color: #27B0B1; }
  .theme-orange .btn-cta__link--underline:after {
    background-color: #F7941D; }
  .theme-purple .btn-cta__link--underline:after {
    background-color: #79478B; }
  .theme-green .btn-cta__link--underline:after {
    background-color: #84A20E; }
  .theme-aqua .theme-aqua .global-header__menu-toggle--dots {
    color: #27B0B1; }
  .theme-aqua .global-header:before {
    background-color: #27B0B1; }
  .theme-aqua .global-header:hover {
    color: #27B0B1; }
  .theme-aqua .theme-orange .global-header__menu-toggle--dots {
    color: #27B0B1; }
  .theme-orange .global-header:before {
    background-color: #F7941D; }
  .theme-orange .global-header:hover {
    color: #F7941D; }
  .theme-aqua .theme-purple .global-header__menu-toggle--dots {
    color: #27B0B1; }
  .theme-purple .global-header:before {
    background-color: #79478B; }
  .theme-purple .global-header:hover {
    color: #79478B; }
  .theme-aqua .theme-green .global-header__menu-toggle--dots {
    color: #27B0B1; }
  .theme-green .global-header:before {
    background-color: #84A20E; }
  .theme-green .global-header:hover {
    color: #84A20E; }
  .theme-aqua .sidenav__link--active {
    color: #27B0B1; }
    .theme-aqua .sidenav__link--active:after {
      background-color: #27B0B1; }
  .theme-orange .sidenav__link--active {
    color: #F7941D; }
    .theme-orange .sidenav__link--active:after {
      background-color: #F7941D; }
  .theme-purple .sidenav__link--active {
    color: #79478B; }
    .theme-purple .sidenav__link--active:after {
      background-color: #79478B; }
  .theme-green .sidenav__link--active {
    color: #84A20E; }
    .theme-green .sidenav__link--active:after {
      background-color: #84A20E; } }

[v-cloak] {
  display: none; }

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s ease; }

.fade-enter, .fade-leave-active {
  opacity: 0; }

.slide-left-enter, .slide-right-leave-active {
  opacity: 0;
  -webkit-transform: translate(30px, 0);
  transform: translate(30px, 0); }

.slide-left-leave-active, .slide-right-enter {
  opacity: 0;
  -webkit-transform: translate(-30px, 0);
  transform: translate(-30px, 0); }

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease; }

.fade-enter, .fade-leave-active {
  opacity: 0; }

@media print {
  .titles-h1 {
    font-weight: 600;
    font-size: 1.875em;
    line-height: 1; }
  .titles-h2 {
    font-weight: 600;
    font-size: 1.25em;
    line-height: 1.1; }
  .titles-h3 {
    font-weight: 600;
    line-height: 1.1;
    font-size: 1.125em; }
  .titles-h4 {
    font-weight: 600;
    line-height: 1.4;
    font-size: 1em; }
  .titles-display1 {
    font-weight: 700;
    font-size: 2.5em;
    line-height: 1.2; }
  .titles-display2 {
    font-weight: 600;
    font-size: 2.125em;
    line-height: 1.05; }
  .titles-display3 {
    font-weight: 600;
    font-size: 1.25em;
    line-height: 1.2; }
  html {
    font-size: 100%; }
  body {
    font-size: 12pt;
    line-height: 1.5; }
  .global-submenu__container,
  .global-header__block-right, .tabbed-scenarios__nav,
  .valuation-input-submit,
  .recommended-crosslinks,
  .print-email-container,
  .print-email__icon,
  .liquidation-calculator__select-label,
  .report-scenarios__create,
  .accordion__heading,
  .tool-input__error,
  .tooltip,
  .chart-scenario__field,
  .tooltip__button,
  .valuation-figure__note,
  .action-tools,
  .flip-card,
  .page-tools,
  .related-tags,
  .heading-entity__figure,
  .profile-details__title,
  .client-brief__visit-site,
  .section-content__ctalist-option,
  .profile-details__visit,
  .section--related-insights,
  .promo-story,
  .hide, .global-footer__utility,
  .social-links,
  .global-footer__menu {
    display: none; }
  .global-header__logo {
    width: 250px; }
  input {
    border: none !important;
    box-shadow: none !important;
    outline: none !important; }
  .section.section--recommended-crosslinks {
    padding-top: 0;
    padding-bottom: 0; }
  .section.section--related-insights {
    padding-top: 0;
    padding-bottom: 0; }
  .section--recommended-crosslinks + .section-content__component {
    margin-top: 3.125em; }
  .rte {
    line-height: 1.5; }
    .rte--light {
      font-weight: 300; }
    .rte--small {
      font-size: 0.875em;
      line-height: 1.4; }
    .rte--lead {
      font-size: 1.25em;
      line-height: 1.25; }
    .rte > *:last-child {
      margin-bottom: 0; }
    .rte > *:first-child {
      margin-top: 0; }
    .rte a {
      color: #248ECB; }
    .rte ul {
      list-style-type: disc;
      padding-left: 1.1em;
      margin-bottom: 1.5em; }
      .rte ul ul {
        list-style-type: circle;
        margin-top: 0.75em; }
    .rte li {
      margin-bottom: 0.3125em; }
    .rte ol {
      padding-left: 1.1em;
      list-style-type: decimal;
      margin-top: 0.3125em;
      margin-bottom: 1.5em; }
      .rte ol ol {
        margin-top: 0.75em; }
    .rte blockquote,
    .rte .blockquote {
      font-weight: 300;
      font-size: 1.25em;
      line-height: 1.2;
      margin: 1.5em 0;
      padding-top: 0.8em;
      border-top: 3px solid #27B0B1; }
      .rte blockquote p,
      .rte .blockquote p {
        margin-bottom: 0.5em; }
      .rte blockquote cite,
      .rte blockquote .cite,
      .rte .blockquote cite,
      .rte .blockquote .cite {
        font-size: 0.7em;
        text-transform: uppercase; }
    .rte cite,
    .rte .cite {
      font-weight: 700;
      font-style: normal;
      font-size: 0.875em;
      color: #868D91; }
    .rte h2,
    .rte .h2-size {
      font-size: 1.25em;
      margin-bottom: 0.5em; }
    .rte h3,
    .rte .h3-size {
      font-size: 1.125em;
      margin-bottom: 1.33333em; }
    .rte h4,
    .rte .h4-size {
      font-size: 1em;
      margin-bottom: 1.5em; }
    .rte h5,
    .rte .h5-size {
      font-size: 0.875em;
      margin-bottom: 1.71429em; }
    .rte h6,
    .rte .h6-size {
      font-size: 0.875em;
      margin-bottom: 1.71429em; }
    .rte img,
    .rte iframe {
      max-width: 100%; } }
