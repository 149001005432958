//------------------------------------------
//				Variables
//------------------------------------------
$font-size-base: 16px;
$font__line-height: 1.5;


$font-size__h1: 30px !default;
$font-size__h2: 20px !default;
$font-size__h3: 18px !default;
$font-size__h4: 16px !default;
$font-size__h5: 14px !default;
$font-size__h6: 14px !default;

$font-size__h1--tablet: 36px !default;
$font-size__h2--tablet: 22px !default;
$font-size__h3--tablet: 22px !default;
$font-size__h4--tablet: 18px !default;


$font-size__display-1: 40px;
$font-size__display-3: 20px;
$font-size__display-4: 20px;

$font-size__display-1--tablet: 54px;
$font-size__display-3--tablet: 22px;
$font-size__display-4--tablet: 24px;

$font-size__display-1--desktop: 64px;

$font-size__heading: 34px !default;
$font-size__heading--tablet: 48px !default;

$font-size__small: 14px !default;
$font-size__smallest: 12px !default;

$font-size__copy: 16px;
$font-size__copy--desktop: 18px;

$font-size__copy-lead: 18px;
$font-size__copy-lead--tablet: 20px;

$font-size__blockquote: 20px;
$font-size__blockquote--tablet: 30px;

$font-size__search: $font-size__h2;
$font-size__search--desktop: $font-size__h2--tablet;

$font-size__global-menu: 26px;


//------------------------------------------
//				Helpful Functions for font conversion to EM
//------------------------------------------
@function px-to-em($pixels, $base: null) {
  @if $base == null {
    $base: $font-size-base;
  }
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

	@return ($pixels / $base) * 1em;
}

@function em($pixels, $base: null) {
  @if $base == null {
    $base: $font-size-base;
  }
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

	@return ($pixels / $base) * 1em;
}

@function rem($pixels, $base: null) {
  @if $base == null {
    $base: $font-size-base;
  }
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }

	@return ($pixels / $base) * 1rem;
}


$font-family__proxima-nova: "proxima-nova";


$font-family__sans-serif:  $font-family__proxima-nova;

$font-weights: (
  light: 300,
  regular: 400,
  semibold: 600,
	bold: 700
);

@mixin font-weight($weight: null) {
	@if map-has-key($font-weights, $weight) {
		font-weight: map-get($font-weights, $weight);
	}
}
