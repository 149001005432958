.workbench-landing {
  min-height: 100vh;
  position: relative;
    
  &__sticky-svg {
    @include transition();
    transition: opacity 0.5s $transition-easing;
    z-index: -1;
    width: 100%;
    position: absolute;
    opacity: 0;

    &--exploded {
      opacity: 1;
      transition: opacity 0.5s $transition-easing 0.5s;
    }
  }

  @include respond-to('landscape') {
    &__sticky-svg {
      max-width: em(350px);
      left: 50%;
      margin-left: em(350px/2) * -1;
    }
  }

  @include respond-to-height('landscape-mobile') {
    min-height: em(650px);
  }

  @include respond-to('landscape') {
    &__sticky-svg {
      max-width: em(350px);
      left: 50%;
      margin-left: em(350px/2) * -1;
    }
  }


  @include respond-to('desktop') {
    &__sticky-svg {
      max-width: none;
      z-index: 1;
      opacity: 1;
      width: 50%;
      margin: 0;
      position: absolute;
      left: auto;
      transition: opacity 0.5s $transition-easing,
        left 1.5s $transition-easing;

      &--right-aligned {
        left: 50%;
      }
      
      &--exploded {
        position: fixed;

        &.workbench-landing__sticky-svg--right-aligned {
          left: 0;
          top: 3.5vh;
        }
      }
      &--stick-bottom {
        position: absolute;
        bottom: 0;
        top: auto !important; //necessary evil
      }

      &--fadeout {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.5s $transition-easing,
        visibility 0.01s linear 0.5s,
          left 1.5s $transition-easing;
      }
    }
  }

  @include respond-to-height('laptop-between', $desktop: true) {
    &__sticky-svg {
      top: 1.5vh;

      &--stick-bottom {
        position: absolute;
        bottom: 0;
      }
    }
  }

  @include respond-to-height('laptop', $desktop: true) {
    min-height: calc(100vh + #{em($toolbox-menu-height--between)});
  }
}
