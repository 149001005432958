
.global-menu {  
  color: $color__white;

  &--purple {
    background-color: $color__brand-purple;
  }
  &--orange {
    background-color: $color__brand-orange;
  }
  &--green {
    background-color: $color__brand-green;
  }
  

  &:not(.global-menu--with-accordion) {
    padding-top: $spacing__sm;
    color: $color__black;
  }

  &--with-accordion {
    border-bottom: 1px solid $color__white;
  }

  &__toggle {
    padding: em($spacing__sm) em($wrapper-padding);
    width: 100%;
    text-align: left;
    color: $color__white;
    text-transform: uppercase;
  }

  &__toggle-title {
    $icon-size: 16px;
    $title-size: 22px;
    @include font-weight(bold);

    font-size: em($title-size);
    display: block;
    color: inherit;
    padding-right: em($icon-size + 2px);
    position: relative;
    

    .icon {
      @include centeralign(vertical);

      right: 0;
      font-size: em($icon-size, $title-size);
      
      &:before {
        @include transition(transform);
        position: relative;
        display: inline-block;
      }
    }
  }

  &__toggle--is-expanded &  {
    &__toggle-title {


      .icon {
        &:before {
          transform: rotate(45deg);
        }

      }
    }
  }

  &__links {
    padding: 0 em($wrapper-padding) $spacing__sm ;
  }

  &__links-option {
    & + & {
      margin-top: em($spacing__unit);
    }
  }

  &__link {
    @include font-weight(semibold);
    
    color: $color__white;
    position: relative;
    font-size: em($font-size__global-menu);
  }

  &__title {
    display: none;
  }

  &__slide-container {
    height: 0;
    overflow: hidden;
    visibility: hidden;
    transition: height $transition-duration $transition-easing,
      visibility 100ms linear $transition-duration;

    &--is-expanded {
      visibility: visible;
      transition: height $transition-duration $transition-easing;
    }
  }

  .underline {
    &--white {
      @include respond-to('between') {
        &:after {
          @include transition(width);
      
          content: '';
          display: block;
          position: absolute;
          left: 0;
          width: 0%;
          height: rem(1px);
          background-color:#fff;
        }
      
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
      &:after {
        background-color: $color__white;
      }
    }
  }


  @include respond-to('between') {
    text-align: left;
    height: 100%;

    &--blue {
      background-color: $color__brand-blue;
    }

    &:not(.global-menu--with-accordion) {
      padding-top: 0;
    }

    &--with-accordion {
      border-bottom: 0 none;
    }
    &__slide-container {
      height: auto;
      overflow: visible;
      visibility: visible;
    }

    &__toggle {
      display: none;
    }
    
    &__link {
      @include font-weight(light);
      color: $color__white;
    }

    &__links {
      padding: em($spacing__gutter);
    }
  
  }
}